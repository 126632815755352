import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../../services/appState.service';
import { AppState } from '../../models/appState.class';
import { CodedDomainCacheService } from '../../services/codedDomainCache.service';
import { CodedDomainItem } from '../../models/codedDomainItem.class';
import { CodedDomainWorkOrderType } from '../../models/codedDomainWorkOrderType.class';
import { WebApiService } from '../../services/webApi.service';

@Component({
  templateUrl: './searchForm.component.html'
})
export class SearchFormComponent {
  appState: AppState;
  webApiService: WebApiService;
  divisions: CodedDomainItem[];
  types: CodedDomainWorkOrderType[];
  statuses: CodedDomainItem[];

  constructor (appStateService: AppStateService, codedDomainCacheService: CodedDomainCacheService, webApiService: WebApiService) {
    this.appState = appStateService.getAppState();
    this.webApiService = webApiService;
    this.divisions = codedDomainCacheService.GetDivisions();
    this.types = codedDomainCacheService.GetTypes();
    this.statuses = codedDomainCacheService.GetStatuses();
  }

  divisionChanged(): void {
    // Reset type when division changes
    this.appState.searchCriteria.workOrderType = "-1";
  }
}
