import { Component, NgZone, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { AppState } from '../../models/appState.class';
import { AppStateService } from '../../services/appState.service';
import { tileLayer, latLng, LatLng, Marker, marker, icon, Layer, Map, LeafletMouseEvent } from 'leaflet';
import { isNumber } from 'util';
import { MapState } from '../../models/mapState.class';
import { ValidationService } from '../../services/validation.service';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { TaskStatus } from '../../models/taskStatus.enum';
import { WebApiService } from '../../services/webApi.service';
import { GeocodeRequest } from '../../models/geocodeRequest.class';

@Component({
  templateUrl: './editLocation.component.html'
})
export class EditLocationComponent implements OnDestroy {
  appState: AppState;
  validation: ValidationService;
  taskStatus = TaskStatus;
  geocodingStatus: TaskStatus = TaskStatus.Pending;
  map: Map;
  leafletOptions: any;
  defaultZoom: number = 16;
  zoom: number;
  layers: Array<Layer> = [];
  center: LatLng;
  currentPointMarker: Marker;  
  mapState: MapState;
  currentLocationType: string;
  gpsWorking: boolean = false;

  // Geocoding modal
  geocodingModal: BsModalRef;
  @ViewChild('geocodingModal')
  geocodingModalTpl: TemplateRef<any>;
  geocodingMessage: string;

  // GPS error modal
  gpsErrorModal: BsModalRef;
  @ViewChild('gpsErrorModal')
  gpsErrorModalTpl: TemplateRef<any>;
  gpsErrorMessage: string;

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }

  get currentLatLng(): LatLng {
    return latLng(this.mapState.lat, this.mapState.lng);
  }

  constructor(appStateService: AppStateService, 
              private _zone: NgZone, 
              validationService: ValidationService,
              private _bsModalService: BsModalService,
              private _webApiService: WebApiService) {
    this.appState = appStateService.getAppState();
    this.mapState = this.appState.workOrder.getMapState();
    this.validation = validationService;
    this.currentLocationType = this.appState.workOrder.locationType;

    // If map center was set in appState then we've seen the map before and should restore previous view state
    if (this.mapState.mapCenter) {
      this.restoreMapSettings();
    }
    else {
      this.center = latLng(this.mapState.lat, this.mapState.lng);
    }    

    this.leafletOptions = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, minZoom: 12 })
      ],
      zoom: this.zoom ? this.zoom : this.defaultZoom,
      center: this.center
    };
  }

  saveMapSettings(locationType: string = ""): void {
    let mapState = this.appState.workOrder.getMapState(locationType);
    mapState.zoom = this.map.getZoom();
    mapState.mapCenter = this.map.getCenter();
    mapState.leafletLayers = this.layers;
    mapState.currentPointMarker = this.currentPointMarker;
  }

  restoreMapSettings(locationType: string = ""): void {
    let mapState = this.appState.workOrder.getMapState(locationType);    
    this.zoom = mapState.zoom ? mapState.zoom : this.defaultZoom;
    this.center = mapState.mapCenter;
    this.layers = mapState.leafletLayers;
    this.currentPointMarker = mapState.currentPointMarker;
    if (this.map) {
      this.map.setZoom(this.zoom, { animate: false })
    }    
  }

  ngOnDestroy(): void {
    this.saveMapSettings();
    this.validation.editLocation.validate([]);
  }

  onMapReady(map: Map) {
    this.map = map;
    this.map.on('click', (e: LeafletMouseEvent) => this.onMapClick(e));
  }

  locationTypeChanged(): void {
    this.saveMapSettings(this.currentLocationType);
    this.mapState = this.appState.workOrder.getMapState();
    this.restoreMapSettings();
    this.currentLocationType = this.appState.workOrder.locationType;
    this.validation.editLocation.revalidate([]);
  }

  onMapClick(event: LeafletMouseEvent) {
    // Only the GPS tab is allowed to modify the map marker
    if (this.appState.workOrder.locationType == "gps" && !this.gpsWorking) {
      // This jquery handler runs outside of Angular's normal change detection zone
      // so we run in the injected zone to make Angular aware of the update
      this._zone.run(() => {
        this.mapState.lat = event.latlng.lat;
        this.mapState.lng = event.latlng.lng;
        this.setCurrentPoint();
        this.validation.editLocation.revalidate(["lat", "lng"]);
      });
    }

  }

  setCurrentPoint(): void {
    if (!this.currentPointMarker) {
      this.currentPointMarker = 
        marker(this.currentLatLng, {        
          icon: icon({
            iconSize: [24, 24],
            iconAnchor: [12, 24],
            iconUrl: 'assets/images/map-marker.png'
          })
        });

      this.layers = [this.currentPointMarker];
    }
    else {
      this.currentPointMarker.setLatLng(this.currentLatLng);
    }

    this.saveMapSettings();
    this.validation.editLocation.revalidate(["mapMarker"]);
  }

  findOnMap(): void {
    if (this.appState.workOrder.locationType == "gps") {
      if (!this.gpsWorking) {
        if (isNumber(+this.mapState.lat) && isNumber(+this.mapState.lng)) {
          this.center = this.currentLatLng;
          this.mapState.mapSearched = true;
          this.setCurrentPoint();
          this.map.setZoom(this.defaultZoom, { animate: false });
        }        
      }
    }
    else {
      this.geocodeAddress();
    }
  }

  geocodeAddress(): void {
    this.geocodingMessage = "Looking up address...";
    this.geocodingStatus = this.taskStatus.Running;
    this.geocodingModal = this._bsModalService.show(this.geocodingModalTpl, this.modalOptions);

    let request = new GeocodeRequest((this.currentLocationType == "address" ? "a" : "i"), this.appState.workOrder.addressNumber, this.appState.workOrder.addressStreet, this.appState.workOrder.addressStreet2);
    this._webApiService.geocode$(request).subscribe(
      data => {
        if (data.success) {
          // Timeout to make sure modal is visible before trying to hide it
          setTimeout(() => {
            this.mapState.lat = +data.latitude;
            this.mapState.lng = +data.longitude;
            this.mapState.mapSearched = true;
            this.setCurrentPoint();
            this.center = this.currentLatLng;
            this.map.setZoom(this.defaultZoom, { animate: false });

            if (data.message)
            {
              this.geocodingStatus = this.taskStatus.Information;              
              this.geocodingMessage = data.message.replace(/(?:\r\n|\r|\n)/g, "<br />");
            }
            else {
              this.closeGeocodingStatus();
            }            
          }, 500);
        }
        else {
          // Display the reason for failure
          this.geocodingMessage = data.message || "There was an error searching for the location.";
          this.geocodingStatus = this.taskStatus.Failure;
        }
      },
      error => {
        // Error trying talking to geocoding service
        this.geocodingMessage = "There was an error searching for the location.";
        this.geocodingStatus = this.taskStatus.Failure;
      });
  };

  // Validation
  getValidationError(field: string): string {
    return this.validation.editLocation.getError(field);
  }

  validateField(field: string): void {
    this.validation.editLocation.validate([field]);
  }

  revalidateField(field: string): void {
    this.validation.editLocation.revalidate([field]);
  }

  creatorTypeChanged(): void {
    this.validation.editLocation.revalidate(["firstName", "lastName", "phone", "email"]);    
  }

  closeGeocodingStatus(): void {
    this.geocodingModal.hide();
  }

  clearMarker(): void {
    this.mapState.clearMarker();
    this.currentPointMarker = null;
    this.layers = [];
    this.validation.editLocation.revalidate(["mapMarker"]);
  }

  gpsAvailable(): boolean {
    return !!navigator.geolocation;
  }

  findMe(): void {
    if (!this.gpsAvailable() || this.gpsWorking) {
      return;
    }

    this.gpsWorking = true;
    navigator.geolocation.getCurrentPosition(
      position => {
        this.gpsWorking = false;
        this.mapState.lat = position.coords.latitude;
        this.mapState.lng = position.coords.longitude;
        this.validation.editLocation.revalidate(["lat", "lng"]);
        this.findOnMap();
      },
      error => {
        // Error codes explained here: https://alligator.io/js/geolocation-api/#error-handling
        this.gpsWorking = false;
        switch(error.code) {
          case 1: // User answered no permission request for location services
            this.gpsErrorMessage = "User denied this application's request for permission to access this device's GPS.";
            break;
          case 2: // GPS was unable to get location
            this.gpsErrorMessage = "GPS was unable to acquire this device's position.";
            break;
          case 3: // Location service did not respond in a timely manner
            this.gpsErrorMessage = "The GPS did not respond to the request.";
            break;
          default:
            this.gpsErrorMessage = "An unexpected error occured attempting to communicate with this device's GPS.";
            break;          
        }
        this.gpsErrorModal = this._bsModalService.show(this.gpsErrorModalTpl, this.modalOptions);
      }
    );
  }

  closeGpsError(): void {
    this.gpsErrorModal.hide();
  }
}
