import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../../services/appState.service';
import { AppState } from '../../models/appState.class';
import { CodedDomainCacheService } from '../../services/codedDomainCache.service';
import { CodedDomainItem } from '../../models/codedDomainItem.class';
import { ValidationService } from '../../services/validation.service';

@Component({
  templateUrl: './reviewRespond.component.html'
})
export class ReviewRespondComponent {
  appState: AppState;
  statuses: CodedDomainItem[];
  validation: ValidationService;
  
  constructor(appStateService: AppStateService, codedDomainCacheService: CodedDomainCacheService, validationService: ValidationService) {
    this.appState = appStateService.getAppState();
    this.statuses = codedDomainCacheService.GetStatuses();
    this.validation = validationService;
  }

  ngOnDestroy(): void {
    this.validation.reviewRespond.validate([]);
  }

  getValidationError(field: string): string {
    return this.validation.reviewRespond.getError(field);
  }

  validateField(field: string): void {
    this.validation.reviewRespond.validate([field]);
  }

  revalidateFields(fields: string[]): void {
    this.validation.reviewRespond.revalidate(fields);
  }

  statusChanged(): void {
    if (this.appState.workOrder.status == "C" && !this.appState.workOrder.completed) {
      this.appState.workOrder.completed = this._setDateToMidnight(new Date());
    }
  }

  private _setDateToMidnight(date: Date): Date {
    let newDate = new Date(date);
    newDate.setUTCHours(0);
    newDate.setUTCMinutes(0);
    newDate.setUTCSeconds(0);
    newDate.setUTCMilliseconds(0);

    return newDate;
  }
}
