import { WorkOrderState } from './workOrderState.class';
import { SearchCriteriaState } from './searchCriteriaState.class';
import { WorkOrder } from './workOrder.class';
import { SearchResults } from './searchResults.class';
import { UserManagerState } from './userManagerState.class';
import { RoleManagerState } from './roleManagerState.class';

export class AppState {
    workOrder: WorkOrderState = new WorkOrderState();
    searchCriteria: SearchCriteriaState = new SearchCriteriaState();
    searchResults: SearchResults;
    userManagerState: UserManagerState = new UserManagerState();
    roleManagerState: RoleManagerState = new RoleManagerState();

    loadWorkOrder(workOrder: WorkOrder): WorkOrderState {
        let workOrderState: WorkOrderState = new WorkOrderState();
        Object.assign(workOrderState, workOrder);
    
        // Set location type based on address data
        if (workOrderState.addressNumber && workOrderState.addressStreet) {
          workOrderState.locationType = "address";
        }
        else if (workOrderState.addressStreet && workOrderState.addressStreet2) {
          workOrderState.locationType = "intersection";
        }
        else {
          workOrderState.locationType = "gps";
        }
    
        // Set creator type based on caller info
        if (workOrderState.callLastName || workOrderState.callFirstName || workOrderState.callPhone || workOrderState.callEmail) {
          workOrderState.creatorType = "caller";
        }
        else {
          workOrderState.creatorType = "internal";
        }
    
        workOrderState.getMapState().initializeFromPoint(workOrderState.shape.y, workOrderState.shape.x);

        this.workOrder = workOrderState;
        return workOrderState;
      }
}

