import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AppStateService } from '../../services/appState.service';
import { Router } from '@angular/router';
import { CredentialService } from '../../services/credential.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  logoutConfirmationModal: BsModalRef;
  @ViewChild('logoutConfirmationModal')
  logoutConfirmationModalTpl: TemplateRef<any>;
  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }

  constructor (private appStateService: AppStateService, 
               private credentialService: CredentialService, 
               private router: Router,
               private bsModalService: BsModalService,
               private validationService: ValidationService) { }

  ngOnInit(): void {
    this.appStateService.resetWorkOrderState();
    this.validationService.reset();
  }

  newWorkOrder(): void {
    this.router.navigate(["/edit/0"]);  
  }

  logout(): void {
    this.logoutConfirmationModal.hide();
    this.credentialService.reset();
    this.router.navigate(["/login"]); 
  }

  showLogoutConfirmation(): void {
    this.logoutConfirmationModal = this.bsModalService.show(this.logoutConfirmationModalTpl, this.modalOptions);
  }

  hideLogoutConfirmation(): void {
    this.logoutConfirmationModal.hide();    
  }
}