export class UserInfo {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    password: string;
    roles: string[];

    constructor(id: string = "", firstName: string = "", lastName: string = "", userName: string = "", password: string = "", roles: string[] = []) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.userName = userName;
        this.password = password;
        this.roles = roles;
    }
}