import { LatLng, Layer, Marker, marker, icon, latLng } from "leaflet";

export class MapState {
    lat: number = 38.924548; // default lat/lng so we aren't out in the middle of the ocean at startup
    lng: number = -89.934785;
    mapSearched: boolean = false;
    mapCenter: LatLng;
    zoom: number;
    leafletLayers: Array<Layer> = [];
    currentPointMarker: Marker;

    constructor() {
        // Display a familiar area but still require a search for a marker
        this.zoom = 12;
        this.initializeFromPoint(this.lat, this.lng, false);
    }

    clearMarker(): void {
        this.mapSearched = false;
        this.leafletLayers = [];
        this.currentPointMarker = null;
    }

    initializeFromPoint(lat: number, lng: number, withMarker: boolean = true): void {
        this.lat = lat;
        this.lng = lng;
        this.mapSearched = withMarker;
        this.mapCenter = new LatLng(this.lat, this.lng);
        if (this.mapSearched) { this.zoom = 16; } // TODO: default zoom. move to settings file

        if (withMarker) {
            this.currentPointMarker = 
                marker(new LatLng(this.lat, this.lng), {        
                    icon: icon({
                        iconSize: [24, 24],
                        iconAnchor: [12, 24],
                        iconUrl: 'assets/images/map-marker.png'
                    })
                });
            this.leafletLayers = [this.currentPointMarker];            
        }
        else {
            this.currentPointMarker = null;
            this.leafletLayers = [];
        }
    }
}