import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead'
import { HttpClientModule } from '@angular/common/http';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { CookieService } from 'ngx-cookie-service';
import { AppStateService } from './services/appState.service';
import { CodedDomainCacheService } from './services/codedDomainCache.service';
import { WebApiService } from './services/webApi.service';
import { CredentialService } from './services/credential.service';
import { ValidationService } from './services/validation.service';

import { AppComponent } from './app.component'
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { EditComponent } from './components/edit/edit.component';
import { EditGeneralComponent } from './components/edit/editGeneral.component';
import { EditLocationComponent } from './components/edit/editLocation.component';
import { EditDescriptionComponent } from './components/edit/editDescription.component';
import { ReviewComponent } from './components/review/review.component';
import { ReviewDetailsComponent } from './components/review/reviewDetails.component';
import { ReviewRespondComponent } from './components/review/reviewRespond.component';
import { SearchComponent } from './components/search/search.component';
import { SearchFormComponent } from './components/search/searchForm.component';
import { SearchResultsComponent } from './components/search/searchResults.component';
import { AccountComponent } from './components/account/account.component';
import { EditUserComponent } from './components/account/editUser.component';
import { UsersComponent } from './components/account/users.component';
import { RolesComponent } from './components/account/roles.component';
import { EditRoleComponent } from './components/account/editRole.component';
import { ManageComponent } from './components/account/manage.component';

import { DivisionFilterPipe } from './pipes/divisionFilter.pipe';
import { WorkOrderPipe } from './pipes/workOrder.pipe';
import { CallerNamePipe } from './pipes/callerName.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { StrippedPhonePipe } from './pipes/strippedPhone.pipe';
import { LocationPipe } from './pipes/location.pipe';
import { LocationIconNamePipe } from './pipes/locationIconName.pipe';
import { SearchResultsCountPipe } from './pipes/searchResultsCount.pipe';
import { AttachmentNumberPipe } from './pipes/attachmentNumber.pipe';
import { AuthGuard } from './guards/auth.guard';
import { UploadProgressPipe } from './pipes/uploadProgress.pipe';
import { RoleFilterPipe } from './pipes/roleFilter.pipe';
import { RoleInfoFilterPipe } from './pipes/roleInfoFilter.pipe';
import { StringSortPipe } from './pipes/stringSort.pipe';
import { ObjectSortPipe } from './pipes/objectSort.pipe';

@NgModule({
  declarations: [
    LoginComponent,
    HomeComponent,
    AppComponent,
    EditComponent,
    EditGeneralComponent,
    EditLocationComponent,
    EditDescriptionComponent,
    ReviewComponent,
    ReviewDetailsComponent,
    ReviewRespondComponent,
    SearchComponent,
    SearchFormComponent,
    SearchResultsComponent,
    AccountComponent,
    EditUserComponent,
    UsersComponent,
    RolesComponent,
    EditRoleComponent,
    ManageComponent,
    DivisionFilterPipe,
    WorkOrderPipe,
    CallerNamePipe,
    PhonePipe,
    StrippedPhonePipe,
    LocationPipe,
    LocationIconNamePipe,
    SearchResultsCountPipe,
    AttachmentNumberPipe,
    UploadProgressPipe,
    RoleFilterPipe,
    RoleInfoFilterPipe,
    StringSortPipe,
    ObjectSortPipe
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      { path: "login", component: LoginComponent },
      { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
      { path: "account", component: AccountComponent, canActivate: [AuthGuard] },
      { path: "manage", component: ManageComponent, canActivate: [AuthGuard] },
      { path: "users", component: UsersComponent, canActivate: [AuthGuard] },
      { path: "editUser", component: EditUserComponent, canActivate: [AuthGuard] },
      { path: "roles", component: RolesComponent, canActivate: [AuthGuard] },
      { path: "editRole", component: EditRoleComponent, canActivate: [AuthGuard] },
      { 
        path: "edit/:id", 
        component: EditComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", redirectTo: "general", pathMatch: "full" },
          { path: "general", component: EditGeneralComponent },
          { path: "location", component: EditLocationComponent },
          { path: "description", component: EditDescriptionComponent }          
        ]
      },
      { 
        path: "review/:id", 
        component: ReviewComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", redirectTo: "details", pathMatch: "full" },
          { path: "details", component: ReviewDetailsComponent },
          { path: "respond", component: ReviewRespondComponent }
        ]
      },
      { 
        path: "search", 
        component: SearchComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", redirectTo: "form", pathMatch: "full" },
          { path: "form", component: SearchFormComponent },
          { path: "results", component: SearchResultsComponent }
        ]
      },
      { path: "", redirectTo: "/home", pathMatch: "full" },
      { path: "**", redirectTo: "/home" }
    ]),
    FormsModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    LeafletModule.forRoot(),
    PinchZoomModule
  ],
  providers: [
    CookieService,
    AppStateService,
    CodedDomainCacheService,
    WebApiService,
    CredentialService,
    ValidationService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
