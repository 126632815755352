import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, RouterLinkActive } from '@angular/router';
import { AppStateService } from '../../services/appState.service';
import { WebApiService } from '../../services/webApi.service';
import { AppState } from '../../models/appState.class';
import { TaskStatus } from '../../models/taskStatus.enum';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './search.component.html'
})
export class SearchComponent {
  currentPath: string;
  appState: AppState;
  taskStatus = TaskStatus;
  searchingStatus: TaskStatus = TaskStatus.Pending;;

  // Searching modal
  searchingModal: BsModalRef;
  @ViewChild('searchingModal')
  searchingModalTpl: TemplateRef<any>;
  searchingMessage: string;

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }

  constructor(location: Location, private _router: Router, 
              private appStateService: AppStateService, 
              private _webApiService: WebApiService,
              private _bsModalService: BsModalService) {
    this.appState = appStateService.getAppState();
    
    _router.events.subscribe(() => {
      this.currentPath = location.path();
    });
  }

  search(): void {
    this.searchingMessage = "Searching work orders...";
    this.searchingStatus = this.taskStatus.Running;
    this.searchingModal= this._bsModalService.show(this.searchingModalTpl, this.modalOptions)
    this._webApiService.searchWorkOrders$(this.appState.searchCriteria).subscribe(
      data => {
        if (data.data && data.data.length > 0) {
          setTimeout(() => {
            this.appState.searchResults = data;
            this._router.navigate(["/search/results"]);
            this.closeSearchingStatus();              
          }, 500);        
        }
        else {
          this.searchingStatus = this.taskStatus.Failure;
          this.searchingMessage = "No matching work orders were found.";          
        }

      },
      error => {
        this.searchingStatus = this.taskStatus.Failure;
        this.searchingMessage = "There was an error searching for work orders.";
      }
    );
  }

  closeSearchingStatus(): void {
    this.searchingModal.hide();
  }
}
