import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../../services/validation.service';
import { AppStateService } from '../../services/appState.service';
import { WorkOrder } from '../../models/workOrder.class';
import { TaskStatus } from '../../models/taskStatus.enum';
import { WorkOrderState } from '../../models/workOrderState.class';
import { MapState } from '../../models/mapState.class';
import { Shape } from '../../models/shape.class';
import { WebApiService } from '../../services/webApi.service';
import { HttpEventType } from '../../../../node_modules/@angular/common/http';

@Component({
  templateUrl: './review.component.html'
})
export class ReviewComponent {
  id: string;
  workOrder: WorkOrderState;
  updatedWorkOrder: WorkOrder;
  location: Location;
  validation: ValidationService;
  taskStatus = TaskStatus;
  savingStatus: TaskStatus = TaskStatus.Pending;
  uploadProgress: number = -1;


    // Validation error modal
    validationErrorsModal: BsModalRef;
    @ViewChild('validationErrorsModal')
    validationErrorsModalTpl: TemplateRef<any>;

    // Saving modal
    savingModal: BsModalRef;
    @ViewChild('savingModal')
    savingModalTpl: TemplateRef<any>;
    savingMessage: string;

    modalOptions: ModalOptions = {
      keyboard: false,
      ignoreBackdropClick: true,
      animated: false,
      class: "modal-dialog-centered"
    }

  constructor(location: Location, 
              private _router: Router,
              activatedRoute: ActivatedRoute,
              validationService: ValidationService, 
              private _bsModalService: BsModalService, 
              appStateService: AppStateService,
              private _webApiService: WebApiService) {
    this.validation = validationService;
    this.workOrder = appStateService.getAppState().workOrder;
    this.location = location;
    activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  showValidationErrors(): void {
    this.validationErrorsModal = this._bsModalService.show(this.validationErrorsModalTpl, this.modalOptions);
  }

  update(): void {
    this.validation.reviewRespond.validate([]);

    if (this.validation.reviewRespond.getErrorCount() > 0) {
      this.showValidationErrors();
    }
    else {
      let postWo: WorkOrderState = new WorkOrderState();      
      Object.assign(postWo, this.workOrder);

      // Flatten map state based on the current location type
      let mapState: MapState = postWo.getMapState();
      postWo.shape = new Shape(mapState.lng, mapState.lat);
      if (postWo.locationType != "address") { postWo.addressNumber = ""; }
      if (postWo.locationType == "gps") { postWo.addressStreet = ""; }
      if (postWo.locationType != "intersection") { postWo.addressStreet2 = ""; }

      // Reset work order date to midnight to avoid sub-date comparison issues
      postWo.workOrderDate = this._setDateToMidnight(postWo.workOrderDate);
      postWo.completed = (postWo.status == "C") ? this._setDateToMidnight(postWo.completed) : null;

      // Remove internal working fields not needed for submission      
      delete postWo['creatorType'];
      delete postWo['locationType'];
      delete postWo['_mapStates'];
      delete postWo['imagesToUpload'];
      delete postWo['imagesProcessed'];
      delete postWo['imageUploadFailures'];

      // PUT work order to server
      this.savingMessage = "Updating work order...";
      this.savingStatus = this.taskStatus.Running;
      this.savingModal = this._bsModalService.show(this.savingModalTpl, this.modalOptions)

      this._webApiService.updateWorkOrder$(postWo).subscribe(
        data => {
          this._webApiService.getWorkOrder$(postWo.id).subscribe(
            data => {
              this.updatedWorkOrder = new WorkOrder();
              Object.assign(this.updatedWorkOrder, data);
              this._uploadImages();
            },
            error => {
              // Error condition;
              this.savingMessage = "There was an error reloading the work order.";
              this.savingStatus = this.taskStatus.Failure; 
            }
          );
        },
        error => {
          // Error condition;
          this.savingMessage = "There was an error updating the work order.";
          this.savingStatus = this.taskStatus.Failure;            
        }
      );
    }    
  }

  private _uploadImages(): void {
    if (this.workOrder.imagesToUpload.length == 0) {
      // Either we didn't have any images to upload or we sent them all
      this.savingMessage = "Work order saved.";
      this.savingStatus = this.taskStatus.Success;

      if (this.getFailureCount() == 0) {
        setTimeout(() => {
          this._reloadWorkOrder();
        }, 2000); 
        
      }      
    }
    else {
      // Make sure images isn't null
      if (!this.updatedWorkOrder.images) {
        this.updatedWorkOrder.images = [];
      }        
    
      this.savingMessage = 
        "Uploading image " + 
        (this.workOrder.imagesProcessed + 1) + 
        " of " + 
        (this.workOrder.imagesToUpload.length + this.workOrder.imagesProcessed) +
        "...";

        this.uploadProgress = 0;
        this._webApiService.uploadImage$(this.updatedWorkOrder.workOrderID, this.workOrder.imagesToUpload[0].file).subscribe(
          event => {
            if (event.type == HttpEventType.UploadProgress) {
              this.uploadProgress = event.loaded / event.total;
            }
            else if (event.type == HttpEventType.Response) {
              this.uploadProgress = -1;
              if (event.body.imageUrl) {
                this.updatedWorkOrder.images.push(event.body.imageUrl);
                this.workOrder.imagesToUpload.shift();              
              }
              else {
                //TODO: Report error
                this.workOrder.imageUploadFailures.push(this.workOrder.imagesToUpload.shift());
              }
              this.workOrder.imagesProcessed++;
              this._uploadImages();
            }
            else {
              this.uploadProgress = -1;
            }
          },
          error => {
            //TODO: Report error
            this.uploadProgress = -1;
            this.workOrder.imageUploadFailures.push(this.workOrder.imagesToUpload.shift());
            this.workOrder.imagesProcessed++;
            this._uploadImages();
          }
      );
    }
  }

  getFailureCount(): number {
    return this.workOrder.imageUploadFailures.length;
  }

  private _reloadWorkOrder(): void {
    // Overwite current work order state with updated return values
    Object.assign(this.workOrder, this.updatedWorkOrder);
    this.workOrder.imagesToUpload = this.workOrder.imageUploadFailures;
    this.workOrder.imageUploadFailures = [];
    this.workOrder.imagesProcessed = 0;
    this.savingModal.hide();
  }

  closeSavingStatus(): void {
    this.savingModal.hide();
  }

  closeAndReload(): void {
    this.closeSavingStatus();
    this._reloadWorkOrder();
  }

  showMe(): void {
    this.validationErrorsModal.hide();
    // This is the only review route with editable fields
    this._router.navigate(["/review/" + this.id + "/respond"]);
  }

  editWorkOrder(): void {
    this._router.navigate(["/edit/" + this.id]);
  }

  private _setDateToMidnight(date: Date): Date {
    let newDate = new Date(date);
    newDate.setUTCHours(0);
    newDate.setUTCMinutes(0);
    newDate.setUTCSeconds(0);
    newDate.setUTCMilliseconds(0);

    return newDate;
  }
}
