import { Component, ViewChild, TemplateRef } from '@angular/core';
import { AppState } from '../../models/appState.class';
import { AppStateService } from '../../services/appState.service';
import { CodedDomainCacheService } from '../../services/codedDomainCache.service';
import { CodedDomainItem } from '../../models/codedDomainItem.class';
import { CodedDomainWorkOrderType } from '../../models/codedDomainWorkOrderType.class';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { tileLayer, Layer } from 'leaflet';
import { WorkOrderState } from '../../models/workOrderState.class';
import { ImageAttachment } from '../../models/ImageAttachment.class';

@Component({
  templateUrl: './reviewDetails.component.html'
})
export class ReviewDetailsComponent {
  appState: AppState;
  workOrder: WorkOrderState;
  divisions: CodedDomainItem[];
  workOrderTypes: CodedDomainWorkOrderType[];
  statuses: CodedDomainItem[];
  leafletOptions: any;
  leafletLayers: Layer[];

  // Map modal
  mapModal: BsModalRef;
  @ViewChild('mapModal')
  mapModalTpl: TemplateRef<any>;
  mapModalLocation: string;

  // Attachment modal
  attachmentModal: BsModalRef;
  @ViewChild('attachmentModal')
  attachmentModalTpl: TemplateRef<any>;
  attachmentIsNew: boolean;
  currentViewAttachment: ImageAttachment;
  currentViewAttachmentSource: any;

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered enable-grow"
  }

  constructor(appStateService: AppStateService, 
              private _codedDomainCacheService: CodedDomainCacheService,
              private _bsModalService: BsModalService) {
    this.appState = appStateService.getAppState();
    this.workOrder = this.appState.workOrder;
    this.divisions = _codedDomainCacheService.GetDivisions();
    this.workOrderTypes = _codedDomainCacheService.GetTypes();
    this.statuses = _codedDomainCacheService.GetStatuses();
    this._bsModalService.onHidden.subscribe(() => {
      this.currentViewAttachmentSource = null;
    });
  }

  getDivisionName(divisionId: string): string {
    if (!this.divisions) {
      return "(Divisions not available)";
    }

    let division = this.divisions.find(division => +divisionId > 0 && +division.value == +divisionId);
    return division ? division.name : ("Division " + divisionId + " not found");
  }

  getWorkOrderTypeName(workOrderTypeId: string): string {
    if (!this.workOrderTypes) {
      return "(Work Order Types not available)";
    }

    let type = this.workOrderTypes.find(type => +workOrderTypeId > 0 && +type.value == +workOrderTypeId);
    return type ? type.name : "(Work Order Type " + workOrderTypeId + " not found)";
  }

  getStatusName(statusId: string): string {
    if (!this.statuses) {
      return ("Statuses not available.");
    }

    let status = this.statuses.find(status => !!status && status.value == statusId);
    return status ? status.name : "(Status " + statusId + " not found)";
  }

  showMap(): void {
    let mapState = this.appState.workOrder.getMapState();
    this.leafletLayers = mapState.leafletLayers;
    this.leafletOptions = {
      layers: [
        tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, minZoom: 12 }),
      ],
      zoom: mapState.zoom,
      center: mapState.mapCenter
    };

    this.mapModal = this._bsModalService.show(this.mapModalTpl, this.modalOptions);
  }

  closeMap(): void {
    this.mapModal.hide();
  }

  openInMaps(): void {
    let mapState = this.appState.workOrder.getMapState();
    window.open("https://www.google.com/maps/place/" + mapState.lat + "," + mapState.lng, "_blank");
    this.closeMap();
  }

  imageCaptured(event): void {
    if (!event || event.target.files.length < 1) {
      return;
    }

    let attachment = new ImageAttachment(event.target.files.item(0));
    this.workOrder.imagesToUpload.push(attachment);

    let reader = new FileReader();
    reader.addEventListener("load", () => {
      attachment.imageData = reader.result;
    }, false);

    reader.readAsDataURL(attachment.file);
    event.srcElement.value = null;
  }

  discardImage(attachment: ImageAttachment) {
    let index: number = this.workOrder.imagesToUpload.indexOf(attachment);
    if (index > -1) {
      this.workOrder.imagesToUpload.splice(index, 1);
    }
  }

  viewNewAttachment(image: ImageAttachment): void {
    this.attachmentIsNew = true;
    this.currentViewAttachment = image;
    this.currentViewAttachmentSource = image.imageData;
    this.attachmentModal = this._bsModalService.show(this.attachmentModalTpl, this.modalOptions);
  }

  viewAttachment(imageUrl: string): void {
    this.attachmentIsNew = false;
    this.currentViewAttachmentSource = imageUrl;
    this.attachmentModal = this._bsModalService.show(this.attachmentModalTpl, this.modalOptions);
  }

  closeAttachment(): void {
    this.attachmentModal.hide();    
  }

  discardAttachment(): void {
    if (this.attachmentIsNew) {      
      this.closeAttachment();
      this.discardImage(this.currentViewAttachment);
      this.currentViewAttachment = null;
    }
  }

  doAttachmentsExist(): boolean {
    if (this.workOrder.imagesToUpload && this.workOrder.imagesToUpload.length > 0)
      return true;

    if (this.workOrder.images && this.workOrder.images.length > 0)
      return true;

    return false;
  }
}
