import { Shape } from "./shape.class";

export class WorkOrder {
    id: number;
    shape: Shape = new Shape();
    workOrderID: string;
    workOrderDate: Date;
    workOrderDescription: string;
    workPerformed: string;
    workOrderTypeId: string = "-1";
    callFirstName: string;
    callLastName: string;    
    callPhone: string;
    callEmail: string;
    addressNumber: string;
    addressStreet: string;
    addressStreet2: string;
    completed: Date;
    divisionId: string = "-1";    
    status: string = "O";
    createdDate: Date;
    createdBy: string;

    images: string[] = [];
}