import { PipeTransform, Pipe } from "../../../node_modules/@angular/core";

@Pipe({
    name: "uploadProgress",
    pure: false
})
export class UploadProgressPipe implements PipeTransform {
    transform(progress: number): string {
        if (isNaN(progress)){
            return;
        }           

        return Math.round(+progress * 100).toString();
    }
}