import { PipeTransform, Pipe } from "@angular/core";
import { SearchResults } from "../models/searchResults.class";

@Pipe({
    name: "searchResultsCount",
    pure: false
})
export class SearchResultsCountPipe implements PipeTransform {
    transform(searchResults: SearchResults): string {
        let message: string;
        if (searchResults.totalCount > searchResults.take) {
            message = searchResults.take + "+";
        }
        else {
            message = searchResults.totalCount.toString();
        }

        message += " match";
        if (searchResults.totalCount != 1) { message += "es"; }
        message += " found";

        return message;
    }
}