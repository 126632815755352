import { Pipe } from "@angular/core";

@Pipe({
    name: "objectSort"
  })
  export class ObjectSortPipe {
    transform(array: Array<any>, args: string): Array<any> {
        let ascending: boolean = true;
        if (!args) {
            return array;
        }
        if (!array || array.length == 0) {
            return array;
        }
        if (args[0] == '-') {
            ascending = false;
            args = args.substr(1);
        }
        if (!array[0][args]) {
            return;
        }

      array.sort((a: any, b: any) => {
        if (a[args] < b[args]) {
          return (ascending) ? -1 : 1;
        } else if (a[args] > b[args]) {
          return (ascending) ? 1 : -1;
        } else {
          return 0;
        }
      });
      return array;
    }
}