export class ChangePasswordRequest {
    Username: string;
    CurrentPassword: string;
    NewPassword: string;

    constructor(username: string, currentPassword: string, newPassword: string) {
        this.Username = username;
        this.CurrentPassword = currentPassword;
        this.NewPassword = newPassword;
    }
}