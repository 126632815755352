import { ValidationState } from "../validationState.class";
import { AppState } from "../appState.class";
import { AppStateService } from "../../services/appState.service";

export abstract class ValidationBase {
    protected _appState: AppState;

    constructor(appStateService: AppStateService) {
        this._appState = appStateService.getAppState();
    }
    
    protected _getClearedState(state: ValidationState): ValidationState {
        state.errorMessage = "";
        state.hasValidated = true;

        return state;
    }

    protected _states: Map<string, ValidationState>;

    getErrorCount(): number {
        return Array
            .from<ValidationState>(this._states.values())
            .filter(state => state.errorMessage)
            .length;
    }

    getError(field: string): string {
        let state = this.getState(field);

        return (state == null) ? "" : state.errorMessage;
    }

    getState(field: string): ValidationState {
        return this._states.has(field) ? this._states.get(field) : null;
    }

    abstract validate(fields: string[]): void;

    revalidate(fields: string[]): void {
        let applicableFields = new Array<string>();
        fields = (fields.length) ? fields : Array.from(this._states.keys());

        fields.forEach(field => {
            if (this._states.get(field).hasValidated) {
                applicableFields.unshift(field);
            }
        });

        if (applicableFields.length) {
            this.validate(applicableFields);
        }        
    }

    reset(): void {
        Array.from(this._states.values()).forEach(state => {
            state.errorMessage = "";
            state.hasValidated = false;
        });
    }
}