import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { AppState } from '../../models/appState.class';
import { AppStateService } from '../../services/appState.service';
import { CodedDomainCacheService } from '../../services/codedDomainCache.service';
import { CodedDomainItem } from '../../models/codedDomainItem.class';
import { ValidationService } from '../../services/validation.service';
import { ImageAttachment } from '../../models/ImageAttachment.class';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { WorkOrderState } from '../../models/workOrderState.class';

@Component({
  templateUrl: './editDescription.component.html'
})
export class EditDescriptionComponent implements OnDestroy {
  divisions: CodedDomainItem[];
  types: CodedDomainItem[];
  validation: ValidationService;
  workOrder: WorkOrderState;

  // Attachment modal
  attachmentModal: BsModalRef;
  @ViewChild('attachmentModal')
  attachmentModalTpl: TemplateRef<any>;
  attachmentIsNew: boolean;
  currentViewAttachment: ImageAttachment;
  currentViewAttachmentSource: any;

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered enable-grow"
  }

  constructor(private _appStateService: AppStateService, 
              codedDomainCacheService: CodedDomainCacheService, 
              validationService: ValidationService,
              private _bsModalService: BsModalService) {
    this.workOrder = this._appStateService.getAppState().workOrder;
    this.divisions = codedDomainCacheService.GetDivisions();
    this.types = codedDomainCacheService.GetTypes();
    this.validation = validationService;
    this._bsModalService.onHidden.subscribe(() => {
      this.currentViewAttachmentSource = null;
    });
  }

  divisionChanged(): void {
    // Reset type when division changes
    this.workOrder.workOrderTypeId = "-1";
  }

  imageCaptured(event): void {
    if (!event || event.target.files.length < 1) {
      return;
    }

    let attachment = new ImageAttachment(event.target.files.item(0));
    this.workOrder.imagesToUpload.push(attachment);

    let reader = new FileReader();
    reader.addEventListener("load", () => {
      attachment.imageData = reader.result;
    }, false);

    reader.readAsDataURL(attachment.file);
    event.srcElement.value = null;
  }

  discardImage(attachment: ImageAttachment) {
    let index: number = this.workOrder.imagesToUpload.indexOf(attachment);
    if (index > -1) {
      this.workOrder.imagesToUpload.splice(index, 1);
    }
  }

  viewNewAttachment(image: ImageAttachment): void {
    this.attachmentIsNew = true;
    this.currentViewAttachment = image;
    this.currentViewAttachmentSource = image.imageData;
    this.attachmentModal = this._bsModalService.show(this.attachmentModalTpl, this.modalOptions);
  }

  viewAttachment(imageUrl: string): void {
    this.attachmentIsNew = false;
    this.currentViewAttachmentSource = imageUrl;
    this.attachmentModal = this._bsModalService.show(this.attachmentModalTpl, this.modalOptions);
  }

  closeAttachment(): void {
    this.attachmentModal.hide();    
  }

  discardAttachment(): void {
    if (this.attachmentIsNew) {      
      this.closeAttachment();
      this.discardImage(this.currentViewAttachment);
      this.currentViewAttachment = null;
    }
  }

  doAttachmentsExist(): boolean {
    if (this.workOrder.imagesToUpload && this.workOrder.imagesToUpload.length > 0)
      return true;

    if (this.workOrder.images && this.workOrder.images.length > 0)
      return true;

    return false;
  }

  ngOnDestroy(): void {
    this.validation.editDescription.validate([]);
  }

  getValidationError(field: string): string {
    return this.validation.editDescription.getError(field);
  }

  validateField(field: string): void {
    this.validation.editDescription.validate([field]);
  }

  revalidateField(field: string): void {
    this.validation.editDescription.revalidate([field]);
  }
}
