import { Component, ViewChild, TemplateRef } from '@angular/core';
import { CredentialService } from '../../services/credential.service';
import { WebApiService } from '../../services/webApi.service';
import { Credentials } from '../../models/credentials.class';
import { TaskStatus } from '../../models/taskStatus.enum';
import { BsModalRef, ModalOptions, BsModalService } from '../../../../node_modules/ngx-bootstrap/modal';
import { UserInfo } from '../../models/userInfo.class';
import { AppStateService } from '../../services/appState.service';
import { UserManagerState } from '../../models/userManagerState.class';
import { Router } from '@angular/router';
import { RoleInfoFilterPipe } from '../../pipes/roleInfoFilter.pipe';

@Component({
  templateUrl: './editUser.component.html'
})
export class EditUserComponent {
  credentials: Credentials;
  userManagerState: UserManagerState;
  userInfo: UserInfo;
  firstNameError: string;
  lastNameError: string;
  userNameError: string;
  passwordError: string;
  confirmPassword: string;
  confirmPasswordError: string;
  validateFailed: boolean = false;
  isAdministrator: boolean = false;
  isDisabled: boolean = false;
  addRoleSelectionVisible: boolean = false;
  selectedRole: string = "";
  displayNoRolesAvailable: boolean = false;
  
  taskStatus = TaskStatus;
  submittingStatus: TaskStatus = TaskStatus.Pending;
  submittingMessage: string;
  submittingTitle: string;
  submittingModal: BsModalRef;
  @ViewChild('submittingModal')
  submittingModalTpl: TemplateRef<any>;

  deleteConfirmStatus: TaskStatus = TaskStatus.Pending;
  deleteConfirmMessage: string;
  deleteConfirmModal: BsModalRef;
  @ViewChild('deleteConfirmationModal')
  deleteConfirmModalTpl: TemplateRef<any>;

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }  

  constructor(credentialService: CredentialService, private _webApiService: WebApiService, private _bsModalService: BsModalService, private _appStateService: AppStateService, private _router: Router) {
    this.credentials = credentialService.credentials;
    this.userManagerState = _appStateService.getAppState().userManagerState;
    this.userInfo = (this.userManagerState.userToEdit) ? this.duplicateUserInfo(this.userManagerState.userToEdit) : new UserInfo();
  }

  duplicateUserInfo(userInfo: UserInfo): UserInfo {
    let dupUser = Object.assign({}, userInfo);
    dupUser.roles = Object.assign([], dupUser.roles);

    var adminIndex = dupUser.roles.findIndex(role => role.toLowerCase() == "admin")
    if (adminIndex > -1) {
      this.isAdministrator = true;
      dupUser.roles.splice(adminIndex, 1);
    }

    var disabledIndex = dupUser.roles.findIndex(role => role.toLowerCase() == "disabled")
    if (disabledIndex > -1) {
      this.isDisabled = true;
      dupUser.roles.splice(disabledIndex, 1);
    }

    return dupUser;
  }
  
  roleSelected(): void {
    if (!this.selectedRole)
      return;

    let role = this.userManagerState.roles.find(role => role.id == this.selectedRole);
    if (!role)
      return;

    this.userInfo.roles.push(role.name);
    this.userInfo.roles.sort((a, b) => { 
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    this.addRoleSelectionVisible = this.areAssignableRolesAvailable();
    setTimeout(() => {
      this.selectedRole = "";  
    }, 10);    
  }

  removeRole(role: string): void {
    this.userInfo.roles.splice(this.userInfo.roles.indexOf(role), 1);
  }

  isOriginalRole(role: string): boolean {
    return (this.userManagerState.userToEdit) ? this.userManagerState.userToEdit.roles.some(origRole => origRole == role) : false;
  }

  areAssignableRolesAvailable(): boolean {
    return new RoleInfoFilterPipe().transform(this.userManagerState.roles, this.userInfo.roles).length > 0;
  }

  displayNoRolesAvailableMessage(): void {
    this.displayNoRolesAvailable = true;

    setTimeout(() => {
      this.displayNoRolesAvailable = false;
    }, 5000);
  }

  validateUserInfo(force: boolean = false): boolean {
    if (!force && !this.validateFailed)
      return;

    let errors: number = 0;
    this.firstNameError = "";
    this.lastNameError = "";
    this.userNameError = "";
    this.passwordError = "";
    this.confirmPasswordError = "";

    if (!this.userInfo.firstName) {
      this.firstNameError = "First name is required.";
      errors++;
    }

    if (!this.userInfo.lastName) {
      this.lastNameError = "Last name is required.";
      errors++;
    }

    if (!this.userInfo.userName) {
      this.userNameError = "User name is required.";
      errors++;
    }

    if (!this.userInfo.id && !this.userInfo.password) {
      this.passwordError = "Password is required.";
      errors++;
    }

    if (this.userInfo.password && this.userInfo.password.length < 6) {
      this.passwordError = "Passwords must be at least 6 characters in length.";
      errors++;
    }   

    if (this.userInfo.password && this.userInfo.password != this.confirmPassword) {
      this.confirmPasswordError = "Passwords do not match.";
      errors++;
    }

    if (errors > 0)
      this.validateFailed = true;

    return errors == 0;
  }

  saveUserInfo(): void {
    if (!this.validateUserInfo(true))
      return;

    this.submittingStatus = TaskStatus.Running;
    this.submittingMessage = "Saving user..."
    this.submittingTitle = "Save User";
    this.submittingModal = this._bsModalService.show(this.submittingModalTpl, this.modalOptions);

    if (this.isAdministrator)
      this.userInfo.roles.unshift("Admin");
    
    if (this.isDisabled)
      this.userInfo.roles.unshift("Disabled");

    ((this.userInfo.id) ? this._webApiService.editUser$(this.userInfo) : this._webApiService.addUser$(this.userInfo)).subscribe(
      data => {
        this.submittingStatus = TaskStatus.Success;
        this.submittingMessage = "User successfully saved."

        if (this.userInfo.id) {
          // Updated existing user
          this.userManagerState.users[this.userManagerState.users.findIndex(user => user.id == this.userInfo.id)] = data;
        }
        else {
          // Added new user
          this.userManagerState.users.push(data);
          this.userManagerState.users.sort((a, b) => { // Last name, First name
            return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()) ||
                   a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
          });
        }

        setTimeout(() => {
          this.userManagerState.returnFromEdit = true;
          this._router.navigate(["/users"]);
          this.closeStatus();
        }, 1500);  
      },
      error => {
        this.submittingStatus = TaskStatus.Failure;
        if (error.error) {
          this.submittingMessage = error.error;
        }
        else {
          this.submittingMessage = "There was an error saving the user.";
        }
      }
    );    
  }

  deleteUser(): void {
    this.closeDeleteConfirmation();
    this.submittingStatus = this.taskStatus.Running;
    this.submittingMessage = "Deleting user...";
    this.submittingTitle = "Delete User";
    this.submittingModal = this._bsModalService.show(this.submittingModalTpl, this.modalOptions);

    this._webApiService.deleteUser$(this.userInfo.id).subscribe(
      data => {
        this.userManagerState.users.splice(this.userManagerState.users.findIndex(user => user.id == this.userInfo.id), 1);
        this.submittingStatus = this.taskStatus.Success;
        this.submittingMessage = "User successfully deleted.";

        setTimeout(() => {
          this.userManagerState.returnFromEdit = true;
          this._router.navigate(["/users"]);
          this.closeStatus();
        }, 1500);
      },
      error => {
        this.submittingStatus = TaskStatus.Failure;
        if (error.error) {
          this.submittingMessage = error.error;
        }
        else {
          this.submittingMessage = "There was an error deleting the user.";
        }
      }
    );
  }

  closeStatus(): void {
    this.submittingModal.hide();
  }

  showDeleteConfirmation(): void {
    this.deleteConfirmModal = this._bsModalService.show(this.deleteConfirmModalTpl, this.modalOptions);
  }

  closeDeleteConfirmation(): void {
    this.deleteConfirmModal.hide();
  }

  goBack(): void {
    this.userManagerState.userToEdit = null;
    this.userManagerState.returnFromEdit = true;
    this._router.navigate(["/users"]);
  }

  goHome(): void {
    this._appStateService.resetUserManagerState();    
    this._router.navigate(["/home"]);
  }
}
