import { Injectable } from '@angular/core';
import { AppState } from '../models/appState.class';
import { WorkOrderState } from '../models/workOrderState.class';
import { SearchCriteriaState } from '../models/searchCriteriaState.class';
import { UserManagerState } from '../models/userManagerState.class';
import { RoleManagerState } from '../models/roleManagerState.class';

@Injectable()
export class AppStateService {
    private _appState = new AppState();

    public getAppState(): AppState {
        return this._appState;
    }

    public setAppState(appState: AppState): void {
        this._appState = appState;
    }

    public resetWorkOrderState(): void {
        this._appState.workOrder = new WorkOrderState();
    }

    public resetSearchCriteriaState(): void {
        this._appState.searchCriteria = new SearchCriteriaState();
    }

    public resetUserManagerState(): void {
        this._appState.userManagerState = new UserManagerState();
    }

    public resetRoleManagerState(): void {
        this._appState.roleManagerState = new RoleManagerState();
    }

    public resetAppState(): void {
        this.resetWorkOrderState();
        this.resetSearchCriteriaState();
        this.resetUserManagerState();
        this.resetRoleManagerState();
    }
}