import { Injectable } from "@angular/core";
import { CodedDomainItem } from "../models/codedDomainItem.class";
import { CodedDomainWorkOrderType } from "../models/codedDomainWorkOrderType.class";

@Injectable()
export class CodedDomainCacheService {
    // Types
    private _types: CodedDomainWorkOrderType[] = new Array<CodedDomainWorkOrderType>();
    SetTypes(types: CodedDomainWorkOrderType[]): void {
        this._types = types;
        this._types = this._types.sort((a, b) => {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            else return 0;
        });
        this._types.unshift({ name: "---- Any Type ----", value: "-1", divisionId: -1 });
    }
    GetTypes(): CodedDomainWorkOrderType[] {
        return this._types;
    }
    ClearTypes(): void {
        this._types = new Array<CodedDomainWorkOrderType>();
    }

    // Statuses
    private _statuses: CodedDomainItem[] = new Array<CodedDomainItem>();
    SetStatuses(statuses: CodedDomainItem[]): void {
        this._statuses = statuses;
        this._statuses.unshift({ name: "---- Any Status ----", value: "-1" });

    }
    GetStatuses(): CodedDomainItem[] {
        return this._statuses;
    }
    ClearStatuses(): void {
        this._statuses = new Array<CodedDomainItem>();
    }

    // Divisions
    private _divisions: CodedDomainItem[] = new Array<CodedDomainItem>();
    SetDivisions(divisions: CodedDomainItem[]) {
        this._divisions = divisions;
        this._divisions.unshift({ name: "---- Any Division ----", value: "-1" });
    }
    GetDivisions(): CodedDomainItem[] {
        return this._divisions;
    }
    ClearDivisions(): void {
        this._divisions = new Array<CodedDomainItem>();
    }

    ClearAll() : void {
        this.ClearTypes();
        this.ClearStatuses();
        this.ClearDivisions();
    }

    AreAllLoaded(): boolean {
        return (this._divisions.length > 0 && this._statuses.length > 0 && this._types.length > 0);
    }

    // For testing until we have API loading implemented
    LoadMockData(): void {
        this.SetTypes([
            { name: "--- Placeholder Type ---", value: "", divisionId: -1 }
        ]);

        this.SetStatuses([
            { name: "--- Placeholder Status ---", value: "" }
        ]);

        this.SetDivisions([
            { name: "--- Placeholder Division ---", value: "" }
        ]);
    }
}