import { PipeTransform, Pipe } from "@angular/core";
import { WorkOrder } from "../models/workOrder.class";

@Pipe({
    name: "strippedPhone",
    pure: false
})
export class StrippedPhonePipe implements PipeTransform {
    transform(workOrder: WorkOrder): string {
        if (!workOrder || !workOrder.callPhone) {
            return "";
        }

        return workOrder.callPhone.replace(/[^\d]/g, '');
    }
}