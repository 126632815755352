import { PipeTransform, Pipe } from "@angular/core";
import { WorkOrder } from "../models/workOrder.class";

@Pipe({
    name: "callerName",
    pure: false
})
export class CallerNamePipe implements PipeTransform {
    transform(workOrder: WorkOrder): string {
        let w = workOrder;
        
        if (w.callFirstName || w.callLastName) {
            let name = w.callLastName;
            if (w.callFirstName) {
                name += ", " + w.callFirstName;
            }

            return name;
        }
        else if (w.createdBy) {
            return "Internal: " + w.createdBy;
        }
    }
}