import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UserInfo } from '../../models/userInfo.class';
import { AppStateService } from '../../services/appState.service';
import { WebApiService } from '../../services/webApi.service';
import { UserManagerState } from '../../models/userManagerState.class';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TaskStatus } from '../../models/taskStatus.enum';
import { Router } from '@angular/router';
import { Credentials } from '../../models/credentials.class';
import { CredentialService } from '../../services/credential.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {
  userManagerState: UserManagerState;
  loading: boolean = true;  
  users: UserInfo[] = [];
  credentials: Credentials;

  taskStatus = TaskStatus;
  loadingStatus: TaskStatus = TaskStatus.Pending;;

  // Loading modal
  loadingModal: BsModalRef;
  @ViewChild('loadingModal')
  loadingModalTpl: TemplateRef<any>;
  loadingMessage: string = "";

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }

  constructor(appStateService: AppStateService, private _webApiService: WebApiService, private _bsModalService: BsModalService, private _router: Router, credentialService: CredentialService) { 
    this.userManagerState = appStateService.getAppState().userManagerState;
    this.credentials = credentialService.credentials;
  }

  ngOnInit() {
    if (!this.userManagerState.returnFromEdit) {
      this.loadingMessage = "Retrieving users...";
      this.loadingStatus = this.taskStatus.Running;
      setTimeout(() => {
        // Necessary due to angular's change detection having an issue with showing modals on init
        this.loadingModal = this._bsModalService.show(this.loadingModalTpl, this.modalOptions)  
      }, 10);
      
      this._webApiService.getUsersAndRoles$().subscribe(
        data => {
          // data is a forkJoin observable with users first and roles second
          this.userManagerState.users = data[0];
          this.userManagerState.roles = data[1];
          this.users = this.userManagerState.users;
          this.loadingStatus = this.taskStatus.Success;
          this.loadingMessage = "User list synchronized.";
          setTimeout(() => {
            this.loading = false;
            this.closeLoadingStatus();
          }, 1000);
        },
        error => {
          this.loadingMessage = "There was an error retrieving users.";
          this.loadingStatus = this.taskStatus.Failure;
        }
      )
    }
    else {
      this.users = this.userManagerState.users;
      this.userManagerState.returnFromEdit = false;
      this.loading = false;
    }
  }

  closeLoadingStatus(): void {
    this.loadingModal.hide();
  }

  editUser(user: UserInfo = null): void {
    this.userManagerState.userToEdit = user;
    this._router.navigate(["/editUser"]);
  }
}
