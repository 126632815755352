import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AppStateService } from '../../services/appState.service';
import { WebApiService } from '../../services/webApi.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TaskStatus } from '../../models/taskStatus.enum';
import { Router } from '@angular/router';
import { RoleManagerState } from '../../models/roleManagerState.class';
import { RoleInfo } from '../../models/roleInfo.class';

@Component({
  templateUrl: './roles.component.html'
})
export class RolesComponent implements OnInit {
  roleManagerState: RoleManagerState;
  loading: boolean = true;  
  roles: RoleInfo[] = [];

  taskStatus = TaskStatus;
  loadingStatus: TaskStatus = TaskStatus.Pending;;

  // Loading modal
  loadingModal: BsModalRef;
  @ViewChild('loadingModal')
  loadingModalTpl: TemplateRef<any>;
  loadingMessage: string = "";

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }

  constructor(appStateService: AppStateService, private _webApiService: WebApiService, private _bsModalService: BsModalService, private _router: Router) { 
    this.roleManagerState = appStateService.getAppState().roleManagerState;
  }

  ngOnInit() {
    if (!this.roleManagerState.returnFromEdit) {
      this.loadingMessage = "Retrieving roles...";
      this.loadingStatus = this.taskStatus.Running;
      setTimeout(() => {
        // Necessary due to angular's change detection having an issue with showing modals on init
        this.loadingModal = this._bsModalService.show(this.loadingModalTpl, this.modalOptions)  
      }, 10);
      
      this._webApiService.getRoles$().subscribe(
        data => {
          this.roleManagerState.roles = data;
          this.roles = this.roleManagerState.roles;
          this.loadingStatus = this.taskStatus.Success;
          this.loadingMessage = "Role list synchronized.";
          setTimeout(() => {
            this.loading = false;
            this.closeLoadingStatus();
          }, 1000);
        },
        error => {
          this.loadingMessage = "There was an error retrieving roles.";
          this.loadingStatus = this.taskStatus.Failure;
        }
      )
    }
    else {
      this.roles = this.roleManagerState.roles;
      this.roleManagerState.returnFromEdit = false;
      this.loading = false;
    }
  }

  closeLoadingStatus(): void {
    this.loadingModal.hide();
  }

  editRole(role: RoleInfo = null): void {
    this.roleManagerState.roleToEdit = role;
    this._router.navigate(["/editRole"]);
  }
}
