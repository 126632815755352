import { Pipe, PipeTransform } from "@angular/core";
import { CodedDomainWorkOrderType } from "../models/codedDomainWorkOrderType.class";

@Pipe({
    name: 'divisionFilter',
    pure: false
})
export class DivisionFilterPipe implements PipeTransform {
    transform(items: CodedDomainWorkOrderType[], filter: string): any {
        if (!items || !filter) {
            return items;
        }
        
        return items.filter(item => item.divisionId == +filter || item.divisionId == -1);
    }
}