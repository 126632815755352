import { Injectable } from "@angular/core";
import 'rxjs/add/observable/throw';
import { Credentials } from "../models/credentials.class";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CredentialService {
    private _credentials: Credentials;
    private _cookieName: string = "highland_wo_credentials";    

    constructor(private _cookieService: CookieService, private _http: HttpClient) { }

    get credentials(): Credentials {
        this._refreshCookie();
        return this._credentials;
    }

    set credentials(credentials: Credentials) {
        this._credentials = credentials;        
        this._cookieService.set(this._cookieName, JSON.stringify(this._credentials), this._getExpireDate(this._credentials.lifeTime));
    }

    isLoggedIn(): boolean {
        this._refreshCookie();     
        return this._credentials != null;
    }

    reset(): void {
        this._credentials = null;
        this._cookieService.delete(this._cookieName);
    }

    private _refreshCookie(): void {
        if (this._cookieService.check(this._cookieName)) {
            let cookieCredentials = Credentials.fromObject(JSON.parse(this._cookieService.get(this._cookieName)));

            if (!cookieCredentials) {
                this.reset();
                return;
            }

            // TODO: Restore when we get sliding expiration window implemented
            //this._cookieService.delete(this._cookieName);
            //this._cookieService.set(this._cookieName, JSON.stringify(cookieCredentials), this._getExpireDate());
            
            if (!this._credentials) {
                this._credentials = cookieCredentials;
            }
        }
        else {
            this.reset();
        }
    }

    private _getExpireDate(seconds: number): Date {
        return new Date(new Date().getTime() + (1000 * seconds));
    }
}