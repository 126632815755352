import { Pipe, PipeTransform } from "@angular/core";
import { WorkOrder } from "../models/workOrder.class";

@Pipe({
    name: "phone",
    pure: false
})
export class PhonePipe implements PipeTransform {
    transform(workOrder: WorkOrder): string {
        if (!workOrder || !workOrder.callPhone) {
            return "";
        }

        let num: string = workOrder.callPhone.replace(/[^\d]/g, '');

        if (num.length == 7) {
            return (
                num.substr(0, 3) + 
                "-" + 
                num.substr(3, 4)
            );
        }

        if (num.length == 11 && num.substr(0, 1) == "1") {
            num = num.substring(1);
        }

        if (num.length == 10) {
            return (
                "(" +
                num.substr(0, 3) +
                ") " +
                num.substr(3, 3) +
                "-" +
                num.substr(6, 4)
            );
        }

        return workOrder.callPhone;
    }
}