import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: "workOrder",
    pure: false
})
export class WorkOrderPipe implements PipeTransform {
    transform(workOrderNumber: string): string {
        if (!workOrderNumber) {
            return "";
        }            

        var strippedString = workOrderNumber.replace(/[^\d]/g, '');
        if (strippedString.length != 10) {
            return strippedString;
        }

        return strippedString.substr(0, 8) + "-" + strippedString.substr(8, 2);
    }
}