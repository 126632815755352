import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CodedDomainCacheService } from './services/codedDomainCache.service';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { TaskStatus } from './models/taskStatus.enum';
import { WebApiService } from './services/webApi.service';
import { CodedDomainItem } from './models/codedDomainItem.class';
import { CodedDomainWorkOrderType } from './models/codedDomainWorkOrderType.class';
import { Location } from '@angular/common';
import { AppStateService } from './services/appState.service';
import { Router } from '@angular/router';
import { WorkOrderState } from './models/workOrderState.class';
import { CredentialService } from './services/credential.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  loadingResources: boolean = true;
  taskStatus = TaskStatus;
  loadingStatus: TaskStatus = this.taskStatus.Pending;
  loadingMessage: string = "";
  workOrderId: number = -1;

  loadingModal: BsModalRef;
  @ViewChild('loadingModal')
  loadingModalTpl: TemplateRef<any>;
  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }

  constructor(private _codedDomainCacheService: CodedDomainCacheService, 
              private _webApiService: WebApiService,
              private _bsModalService: BsModalService,
              private _location: Location,
              private _appStateService: AppStateService,
              private _router: Router,
              private _credentialService: CredentialService) {
  }

  ngOnInit(): void {
    let routeSegments = this._location.path().split('/');
    if (routeSegments.length >= 3 && (routeSegments[1] == "edit" || routeSegments[1] == "review") && +routeSegments[2] >= 0) {
      this.workOrderId = +routeSegments[2];
    }

    if (!this._codedDomainCacheService.AreAllLoaded() && this._credentialService.isLoggedIn()) {
      // setTimeout is necessary to avoid issues with angular's change 
      // detection related to showing modals from OnInit()
      setTimeout(() => {
        this.loadResources();  
      }, 10);      
    }
    else {
      this.loadingResources = false;
    }
  }

  loadResources(): void {
    this.loadingStatus = this.taskStatus.Running;
    this.loadingMessage = "Synchornizing resources...";

    if (!this.loadingModal)   {
      this.loadingModal = this._bsModalService.show(this.loadingModalTpl, this.modalOptions)
    }

    // Download latest coded domain data for dropdowns
    this._webApiService.getCodedDomainData$().subscribe(
      codedDomainData => {
        // Elements: 0 = Divisions, 1 = Work order types, 2 = Status codes
        this._codedDomainCacheService.SetDivisions(<CodedDomainItem[]>codedDomainData[0].map(item => {
          return new CodedDomainItem(item.name, item.id);
        }));
        this._codedDomainCacheService.SetStatuses(<CodedDomainItem[]>codedDomainData[1].map(item => {
          return new CodedDomainItem(item.value, item.code);
        })); 
        this._codedDomainCacheService.SetTypes(<CodedDomainWorkOrderType[]>codedDomainData[2].map(type => {
          return new CodedDomainWorkOrderType(type.type, type.id, type.divisionId);
        }));

        if (this.workOrderId > 0) {
          this.loadWorkOrder(this.workOrderId);
        }
        else {
          if (this.workOrderId == -1) {
            this._router.navigate(['/home']);
          }          
          this.successfullyDismissLoadingModal();           
        }          
      },
      error => {
        this.loadingMessage = "Unable to communicate with server.";
        this.loadingStatus = TaskStatus.Failure;
      });
  }

  loadWorkOrder(id: number): void {
    // Try to load the work order in the URL but failing that just go to the home page
    this._webApiService.getWorkOrder$(id).subscribe(
      data => {
        if (data && +data.id > 0) {          
          this._appStateService.getAppState().loadWorkOrder(data);
        }
        else {
          this._router.navigate(['/home']);
        }

        this.successfullyDismissLoadingModal();
      },
      error => {
        this._router.navigate(['home']);
        this.successfullyDismissLoadingModal();
      }
    );
  }

  successfullyDismissLoadingModal(): void {
    this.loadingStatus = TaskStatus.Success;

    setTimeout(() => {
      this.loadingModal.hide();
      this.loadingResources = false;
    }, 500); 
  }
}
