import { PipeTransform, Pipe } from "@angular/core";
import { WorkOrder } from "../models/workOrder.class";

@Pipe({
    name: "locationIconName",
    pure: false
})
export class LocationIconNamePipe implements PipeTransform {
    transform(workOrder: WorkOrder): string {
        let w = workOrder;

        if (w.addressNumber && w.addressStreet) {
            return "road";
        }
        else if (w.addressStreet && w.addressStreet2) {
            return "map-signs";
        }
        else if (w.shape.x && w.shape.y) {
            return "globe";
        }
        else {
            return "question-circle";
        }
    }
}