import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CredentialService } from '../../services/credential.service';
import { WebApiService } from '../../services/webApi.service';
import { CodedDomainCacheService } from '../../services/codedDomainCache.service';
import { Router } from '@angular/router';
import { TaskStatus } from '../../models/taskStatus.enum';
import { CodedDomainWorkOrderType } from '../../models/codedDomainWorkOrderType.class';
import { CodedDomainItem } from '../../models/codedDomainItem.class';
import { JwtHelperService } from '../../../../node_modules/@auth0/angular-jwt';
import { Credentials } from '../../models/credentials.class';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent {
  userName: string = '';
  password: string = "";
  private _cookieName: string = "lastSuccessfulUsername";

  taskStatus = TaskStatus;
  authenticatingStatus: TaskStatus = TaskStatus.Pending;
  authenticatingMessage: string = "";
  loadingStatus: TaskStatus = TaskStatus.Pending;
  loadingMessage: string = "";  
  
  loadingModal: BsModalRef;
  @ViewChild('loadingModal')
  loadingModalTpl: TemplateRef<any>;
  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }  

  constructor(private _credentialService: CredentialService, 
              private _webApiService: WebApiService, 
              private _codedDomainCacheService: CodedDomainCacheService, 
              private _router: Router,
              private _bsModalService: BsModalService,
              private _cookieService: CookieService) {
    if (_cookieService.check(this._cookieName)) {
      this.userName = _cookieService.get(this._cookieName);
    }
  }

  login(): void {
    this.loadingStatus = TaskStatus.Pending;
    this.authenticatingStatus = TaskStatus.Running;
    this.authenticatingMessage = "Verifying credentials"
    this.loadingModal = this._bsModalService.show(this.loadingModalTpl, this.modalOptions)

    // Log in
    this._webApiService.authenticate$(this.userName, this.password).subscribe(
      authenticationData => {
        if (authenticationData.id) {
          const jwtHelper = new JwtHelperService();
          const token = jwtHelper.decodeToken(authenticationData.auth_token);
          this._credentialService.credentials = new Credentials(authenticationData.id, authenticationData.auth_token, +authenticationData.expires_in);

          this._cookieService.delete(this._cookieName);
          this._cookieService.set(this._cookieName, token.sub, this._getExpireDate(60 * 60 * 24 * 30)); // 1 month

          this.authenticatingStatus = TaskStatus.Success;
          this.loadingMessage = "Loading asset data"
          this.loadingStatus = TaskStatus.Running;

          // Download latest coded domain data for dropdowns
          this._webApiService.getCodedDomainData$().subscribe(
            codedDomainData => {
              // Elements: 0 = Divisions, 1 = Work order types, 2 = Status codes
              this._codedDomainCacheService.SetDivisions(<CodedDomainItem[]>codedDomainData[0].map(item => {
                return new CodedDomainItem(item.name, item.id);
              }));
              this._codedDomainCacheService.SetStatuses(<CodedDomainItem[]>codedDomainData[1].map(item => {
                return new CodedDomainItem(item.value, item.code);
              })); 
              this._codedDomainCacheService.SetTypes(<CodedDomainWorkOrderType[]>codedDomainData[2].map(type => {
                return new CodedDomainWorkOrderType(type.type, type.id, type.divisionId);
              }));

              this.loadingStatus = TaskStatus.Success;

              setTimeout(() => {
                this.loadingModal.hide();
                this._router.navigate(["/home"]);            
              }, 1500);            
            },
            error => {
              this.loadingMessage = error.message;
              this.loadingStatus = TaskStatus.Failure;
            });
        }
        else {
          this.authenticatingStatus = TaskStatus.Failure;
          this.authenticatingMessage = "Invalid username and/or password.";
        }
    },
    error => {
      this.authenticatingMessage = (error && error.error) ? error.error : "Invalid username and/or password.";
      this.authenticatingStatus = TaskStatus.Failure;
    });
  }

  closeStatus(): void {
    this.loadingModal.hide();
  }

  private _getExpireDate(seconds: number): Date {
    return new Date(new Date().getTime() + (1000 * seconds));
  }
}