import { ValidationBase } from "./validationBase.class";
import { AppStateService } from "../../services/appState.service";
import { ValidationState } from "../validationState.class";
import { WorkOrderState } from "../workOrderState.class";

export class EditGeneralValidation extends ValidationBase {
    private _emailCheck: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    private _phoneCheck: RegExp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);

    constructor(appStateService: AppStateService) {
        super(appStateService);

        this._states = new Map<string, ValidationState>([
            ["date", new ValidationState()],
            ["firstName", new ValidationState()],
            ["lastName", new ValidationState()],
            ["phone", new ValidationState()],
            ["email", new ValidationState()]
        ]);
    }

    validate(fields: string[]): void {
        let w: WorkOrderState = this._appState.workOrder;
        let v: ValidationState;

        // Date
        if (fields.indexOf("date") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("date"));
            if (!w.workOrderDate) {
                v.errorMessage = "Creation date is required.";
            }
            else if (new Date(w.workOrderDate.toString() + " 00:00:00") > new Date()) {
                v.errorMessage = "Creation date cannot be in the future.";
            }
        }

        // We only care about the rest if the creator is manually entered
        // First name
        // if (fields.indexOf("firstName") > -1 || !fields.length) {
        //     v = this._getClearedState(this._states.get("firstName"));
        //     if (w.creatorType == "caller") {
        //          if (!w.callFirstName) {
        //             v.errorMessage = "First name is required.";
        //         }
        //     }
        // }

        // Last name
        if (fields.indexOf("lastName") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("lastName"));
            if (w.creatorType == "caller") {
                if (!w.callLastName) {
                    v.errorMessage = "Last name is required.";
                }    
            }
        }

        // Phone
        if (fields.indexOf("phone") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("phone"));
            if (w.creatorType == "caller") {
                if (w.callPhone && !this._phoneCheck.test(w.callPhone)) {
                    v.errorMessage = "Phone number is not valid.";
                }    
            }
        }

        // Email
        if (fields.indexOf("email") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("email"));
            if (w.creatorType == "caller") {
                if (w.callEmail && !this._emailCheck.test(w.callEmail)) {
                    v.errorMessage = "Email address is not valid.";
                }    
            }
        }
    }
}