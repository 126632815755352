import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { forkJoin } from 'rxjs';
import { CodedDomainItem } from "../models/codedDomainItem.class";
import { CodedDomainWorkOrderType } from "../models/codedDomainWorkOrderType.class";
import { WorkOrder } from "../models/workOrder.class";
import { SearchCriteriaState } from "../models/searchCriteriaState.class";
import { SearchResults } from "../models/searchResults.class";
import { UploadResult } from "../models/uploadResult.class";
import { CredentialService } from "./credential.service";
import { isArray } from "util";
import { AuthenticateResponse } from "../models/authenticateResponse.class";
import { ChangePasswordRequest } from "../models/changePasswordRequest.class";
import { UserInfo } from "../models/userInfo.class";
import { RoleInfo } from "../models/roleInfo.class";
import { environment } from "../../environments/environment";
import { GeocodeRequest } from "../models/geocodeRequest.class";
import { GeocodeResult } from "../models/geocodeResult.class";

@Injectable()
export class WebApiService {
    private _host: string = environment.apiUrl;    
    private _divisionRoute: string = "/api/division";
    private _workOrderTypeRoute: string = "/api/workOrderType";
    private _workOrderRoute: string = "/api/workOrder";
    private _statusRoute: string = "/api/status";
    private _streetSuggestionRoute: string = "/api/street";
    private _imageUploadRoute: string = "/api/image";
    private _authRoute: string = "/api/auth/login";
    private _changePasswordRoute: string = "/api/accounts/changePassword";
    private _userCountRoute: string = "/api/accounts/count";
    private _usersRoute: string = "/api/accounts";
    private _rolesRoute: string = "/api/roles";
    private _geocodeRoute: string = "/api/geocode";

    constructor(private _http: HttpClient, private _credentialService: CredentialService) {}

    // I need to do this weird typing so http's GET will accept it
    getHttpOptions(additional: any[] = []): { headers?: HttpHeaders } {
        let opts = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this._credentialService.credentials.token
            })
        };
        additional.forEach(section => {
            if (isArray(section) && section.length == 2) {
                opts[section[0]] = section[1];
            }
        });

        return opts;
    }

    getCodedDomainData$(): Observable<any> {
        return forkJoin(
            this._http.get<CodedDomainItem[]>(this._host + this._divisionRoute, this.getHttpOptions()),            
            this._http.get<CodedDomainItem[]>(this._host + this._statusRoute, this.getHttpOptions()),
            this._http.get<CodedDomainWorkOrderType[]>(this._host + this._workOrderTypeRoute, this.getHttpOptions())
        );
    }

    submitWorkOrder$(workOrder: WorkOrder): Observable<WorkOrder> {
        return this._http.post<WorkOrder>(this._host + this._workOrderRoute, workOrder, this.getHttpOptions());
    }

    updateWorkOrder$(workOrder: WorkOrder): Observable<any> {
        return this._http.put<any>(this._host + this._workOrderRoute + "/" + workOrder.id, workOrder, this.getHttpOptions());
    }

    getWorkOrder$(id: number): Observable<WorkOrder> {
        return this._http.get<WorkOrder>(this._host + this._workOrderRoute + "/" + id, this.getHttpOptions());
    }

    searchWorkOrders$(searchCriteria: SearchCriteriaState): Observable<SearchResults> {
        let searchParams: HttpParams = new HttpParams();
        Object.keys(searchCriteria).forEach(prop => {
            switch(prop) {
                case "divisionId":
                case "workOrderType":
                case "status":
                    if (searchCriteria[prop] != "-1") {
                        searchParams = searchParams.append(prop, searchCriteria[prop]);
                    }
                    break;
                default:
                    if (searchCriteria[prop]) {
                        searchParams = searchParams.append(prop, searchCriteria[prop]);
                    }
                    break;
            }            
        });        
        
        return this._http.get<SearchResults>(this._host + this._workOrderRoute, this.getHttpOptions([['params', searchParams]]));
    }

    getStreetSuggestions$(streetFragment: string): Observable<string[]> {
        let encodedFragment = (streetFragment) ? "/" + encodeURI(streetFragment) : "";
        return this._http.get<string[]>(this._host + this._streetSuggestionRoute + encodedFragment, this.getHttpOptions());
    }

    uploadImage$(workOrderId: string, image: File): Observable<HttpEvent<UploadResult>> {
        const formData: FormData = new FormData();
        formData.append('workOrderId', workOrderId);
        formData.append('image', image);

        const request = new HttpRequest("POST", this._host + this._imageUploadRoute, formData, this.getHttpOptions([['reportProgress', true]]));
        return this._http.request(request);
    }

    authenticate$(userName: string, password: string): Observable<AuthenticateResponse> {
        return this._http.post<AuthenticateResponse>(this._host + this._authRoute, { userName: userName, password: password});
    }

    changePassword$(request: ChangePasswordRequest): Observable<any> {
        return this._http.post<any>(this._host + this._changePasswordRoute, request, this.getHttpOptions());
    }

    getUsers$(): Observable<UserInfo[]> {
        return this._http.get<UserInfo[]>(this._host + this._usersRoute, this.getHttpOptions());
    }

    getUserCount$(): Observable<number> {
        return this._http.get<number>(this._host + this._userCountRoute, this.getHttpOptions());
    }

    getUsersAndRoles$(): Observable<[UserInfo[], RoleInfo[]]> {
        return forkJoin(
            this.getUsers$(),
            this.getRoles$()
        );
    }

    getUserCountAndRoles$(): Observable<[number, RoleInfo[]]> {
        return forkJoin(
            this.getUserCount$(),
            this.getRoles$()
        );
    }

    addUser$(userInfo: UserInfo): Observable<UserInfo> {
        return this._http.post<UserInfo>(this._host + this._usersRoute, userInfo, this.getHttpOptions());
    }

    editUser$(userInfo: UserInfo): Observable<UserInfo> {
        return this._http.put<UserInfo>(this._host + this._usersRoute, userInfo, this.getHttpOptions());
    }

    deleteUser$(id: string): Observable<any> {
        return this._http.delete<any>(this._host + this._usersRoute + "?id=" + id, this.getHttpOptions());
    }

    getRoles$(): Observable<RoleInfo[]> {
        return this._http.get<RoleInfo[]>(this._host + this._rolesRoute, this.getHttpOptions());
    }

    addRole$(roleInfo: RoleInfo): Observable<RoleInfo> {
        return this._http.post<RoleInfo>(this._host + this._rolesRoute, roleInfo, this.getHttpOptions());
    }

    editRole$(roleInfo: RoleInfo): Observable<RoleInfo> {
        return this._http.put<RoleInfo>(this._host + this._rolesRoute, roleInfo, this.getHttpOptions());
    }

    deleteRole$(id: string, migrateMembers: boolean, migrationRoleId: string): Observable<any> {
        return this._http.delete<any>(this._host + this._rolesRoute + "?id=" + id + "&migrateMembers=" + migrateMembers.toString() + "&migrationRoleId=" + migrationRoleId, this.getHttpOptions());
    }

    geocode$(request: GeocodeRequest): Observable<GeocodeResult> {
        let params = [];
        params.push(`searchType=${encodeURI(request.searchType)}`);
        if (request.houseNumber) params.push(`houseNumber=${encodeURI(request.houseNumber)}`);
        if (request.street1) params.push(`street1=${encodeURI(request.street1)}`);
        if (request.street2) params.push(`street2=${encodeURI(request.street2)}`);
        if (request.querySuffix) params.push(`querySuffix=${encodeURI(request.querySuffix)}`);

        return this._http.get<GeocodeResult>(this._host + this._geocodeRoute + "?" + params.join('&'), this.getHttpOptions());
    }
}