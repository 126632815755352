export class RoleInfo {
    id: string;
    name: string;
    memberCount: number;

    constructor(id: string = "", name: string = "", memberCount = 0) {
        this.id = id;
        this.name = name;
        this.memberCount = memberCount;
    }
}