import { ValidationBase } from "./validationBase.class";
import { AppStateService } from "../../services/appState.service";
import { ValidationState } from "../validationState.class";
import { WorkOrderState } from "../workOrderState.class";

export class EditLocationValidation extends ValidationBase {
    
    constructor(appStateService: AppStateService) {
        super(appStateService);

        this._states = new Map<string, ValidationState>([
            ["addressHouseNumber", new ValidationState()],
            ["addressStreet1addr", new ValidationState()],
            ["addressStreet1intr", new ValidationState()],
            ["addressStreet2", new ValidationState()],
            ["lat", new ValidationState()],
            ["lng", new ValidationState()],
            ["mapMarker", new ValidationState()]
        ]);
    }

    validate(fields: string[]): void {
        let w: WorkOrderState = this._appState.workOrder;
        let v: ValidationState;

        // House number
        if (fields.indexOf("addressHouseNumber") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("addressHouseNumber"));
            if (w.locationType == "address") {
                if (!w.addressNumber) {
                    v.errorMessage = "House number is required.";
                }
            }
        }

        // Street (address tab)
        if (fields.indexOf("addressStreet1addr") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("addressStreet1addr"));
            if (w.locationType == "address") {
                if (!w.addressStreet) {
                    v.errorMessage = "Street name is required.";
                }
            }
        }

        // Cross street 1
        if (fields.indexOf("addressStreet1intr") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("addressStreet1intr"));
            if (w.locationType == "intersection") {
                if (!w.addressStreet) {
                    v.errorMessage = "First cross street name is required.";
                }
            }
        }

        // Cross street 2
        if (fields.indexOf("addressStreet2") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("addressStreet2"));
            if (w.locationType == "intersection") {
                if (!w.addressStreet2) {
                    v.errorMessage = "Second cross street name is required.";
                }
            }
        }

        // GPS latitude
        if (fields.indexOf("lat") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("lat"));
            if (w.locationType == "gps") {
                if (!w.getMapState().lat) {
                    v.errorMessage = "Latitude is required.";
                }
            }
        }

        // GPS longitude
        if (fields.indexOf("lng") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("lng"));
            if (w.locationType == "gps") {
                if (!w.getMapState().lng) {
                    v.errorMessage = "Longitude is required.";
                }
            }
        }

        // Map marker exists on map
        if (fields.indexOf("mapMarker") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("mapMarker"));
            if (!w.getMapState().leafletLayers.length) {
                v.errorMessage = "Valid map marker required.";
            }
        }
    }
}