import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppStateService } from '../../services/appState.service'
import { AppState } from '../../models/appState.class';
import { Credentials } from '../../models/credentials.class';
import { CredentialService } from '../../services/credential.service';
import { ValidationService } from '../../services/validation.service';
import { DatePipe } from '@angular/common';

@Component({
  templateUrl: './editGeneral.component.html'
})
export class EditGeneralComponent implements OnDestroy {
  appState: AppState;
  credentials: Credentials;
  validation: ValidationService;

  constructor(appStateService: AppStateService, credentialService: CredentialService, validationService: ValidationService) {
    this.appState = appStateService.getAppState();
    this.credentials = credentialService.credentials;
    this.validation = validationService;

    if (!this.appState.workOrder.workOrderDate) {
      this.appState.workOrder.workOrderDate = new Date();
    }
  }

  ngOnDestroy(): void {
    this.validation.editGeneral.validate([]);
  }

  getValidationError(field: string): string {
    return this.validation.editGeneral.getError(field);
  }

  validateField(field: string): void {
    this.validation.editGeneral.validate([field]);
  }

  revalidateField(field: string): void {
    this.validation.editGeneral.revalidate([field]);
  }

  creatorTypeChanged(): void {
    this.validation.editGeneral.revalidate(["firstName", "lastName", "phone", "email"]);    
  }
}
