export class GeocodeRequest {
    searchType: string;
    houseNumber: string;
    street1: string;
    street2: string;
    querySuffix: string;

    constructor(searchType, houseNumber = null, street1 = null, street2 = null, querySuffix = null) {
        this.searchType = searchType;
        this.houseNumber = houseNumber;
        this.street1 = street1;
        this.street2 = street2;
        this.querySuffix = querySuffix;
    }
}