import { Injectable } from "@angular/core";
import { ValidationState } from "../models/validationState.class";
import { AppState } from "../models/appState.class";
import { AppStateService } from "./appState.service";
import { WorkOrderState } from "../models/workOrderState.class";
import { EditGeneralValidation } from "../models/validation/editGeneralValidation.class";
import { EditLocationValidation } from "../models/validation/editLocationValidation.class";
import { EditDescriptionValidation } from "../models/validation/editDescriptionValidation.class";
import { ReviewRespondValidation } from "../models/validation/reviewRespondValidation.class";

@Injectable()
export class ValidationService {
    private _editGeneralValidation: EditGeneralValidation;
    private _editLocationValidation: EditLocationValidation;
    private _editDescriptionValidation: EditDescriptionValidation;
    private _reviewRespondValidation: ReviewRespondValidation;

    constructor(appStateService: AppStateService) {
        this._editGeneralValidation = new EditGeneralValidation(appStateService);
        this._editLocationValidation = new EditLocationValidation(appStateService);
        this._editDescriptionValidation = new EditDescriptionValidation(appStateService);
        this._reviewRespondValidation = new ReviewRespondValidation(appStateService);
    }

    get editGeneral(): EditGeneralValidation {
        return this._editGeneralValidation;
    }

    get editLocation(): EditLocationValidation {
        return this._editLocationValidation;
    }

    get editDescription(): EditDescriptionValidation {
        return this._editDescriptionValidation;
    }

    get reviewRespond(): ReviewRespondValidation {
        return this._reviewRespondValidation;
    }

    reset(): void {
        this._editGeneralValidation.reset();
        this._editLocationValidation.reset();
        this._editDescriptionValidation.reset();
        this._reviewRespondValidation.reset();
    }
}