import { CanActivate, Router } from "@angular/router";
import { CredentialService } from "../services/credential.service";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private _credentialService: CredentialService, private _router: Router) { }

    canActivate(): boolean {
        if (this._credentialService.isLoggedIn()) {
            return true;
        }
        else {
            this._router.navigate(["login"]);
            return false;
        }
        
    }
}