import { ValidationBase } from "./validationBase.class";
import { AppStateService } from "../../services/appState.service";
import { ValidationState } from "../validationState.class";
import { WorkOrderState } from "../workOrderState.class";

export class ReviewRespondValidation extends ValidationBase {
    constructor(appStateService: AppStateService) {
        super(appStateService);

        this._states = new Map<string, ValidationState>([
            ["workPerformed", new ValidationState()],
            ["status", new ValidationState()],
            ["completedDate", new ValidationState()]
        ]);
    }

    validate(fields: string[]): void {
        let w: WorkOrderState = this._appState.workOrder;
        let v: ValidationState;

        // Work Performed / Action Taken
        // if (fields.indexOf("workPerformed") > -1 || !fields.length) {
        //     v = this._getClearedState(this._states.get("workPerformed"));
        //     if (!w.workPerformed) {
        //         v.errorMessage = "Responses must describe actions taken.";
        //     }
        // }

        // Work order status
        // if (fields.indexOf("status") > -1 || !fields.length) {
        //     v = this._getClearedState(this._states.get("status"));
        //     if (+w.status == -1) {
        //         v.errorMessage = "A work order status must be specified.";
        //     }
        // }

        // Work order completed date
        if (fields.indexOf("completedDate") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("completedDate"));
            if (w.completed && new Date(w.completed.toString() + " 00:00:00") > new Date()) {
                v.errorMessage = "Completion date cannot be in the future.";
            }
        }
    }
}