import { DatePipe } from "@angular/common";

export class SearchCriteriaState {
    workOrderDateFrom: string = new DatePipe(navigator.language).transform(new Date(), 'MM/dd/yyyy');
    workOrderDateTo: string;
    workOrderId: string;
    divisionId: string = "-1";
    workOrderType: string = "-1";
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    houseNumber: string;
    street: string;
    description: string;
    actionTaken: string;
    status: string = "-1";
    completedFrom: string;
    completedTo: string;
}