import { Component } from '@angular/core';
import { AppStateService } from '../../services/appState.service';
import { AppState } from '../../models/appState.class';
import { WorkOrder } from '../../models/workOrder.class';
import { Router } from '@angular/router';
import { CodedDomainWorkOrderType } from '../../models/codedDomainWorkOrderType.class';
import { CodedDomainCacheService } from '../../services/codedDomainCache.service';
import { CodedDomainItem } from '../../models/codedDomainItem.class';

@Component({
  templateUrl: './searchResults.component.html'

})
export class SearchResultsComponent {
  appState: AppState;
  divisions: CodedDomainItem[];
  workOrderTypes: CodedDomainWorkOrderType[];
  statuses: CodedDomainItem[];

  constructor(appStateService: AppStateService, private _router: Router, codedDomainCacheService: CodedDomainCacheService) {
    this.appState = appStateService.getAppState();
    this.divisions = codedDomainCacheService.GetDivisions();
    this.workOrderTypes = codedDomainCacheService.GetTypes();
    this.statuses = codedDomainCacheService.GetStatuses();
  }

  loadWorkOrder(workOrder: WorkOrder): void {
    this.appState.loadWorkOrder(workOrder);
    this._router.navigate(["/review/" + workOrder.id + "/details"]);
  }

  getDivisionName(divisionId: string): string {
    if (!this.divisions) {
      return "(Divisions not available)";
    }

    let division = this.divisions.find(division => +divisionId > 0 && +division.value == +divisionId);
    return division ? division.name : ("Division " + divisionId + " not found");
  }

  getWorkOrderTypeName(workOrderTypeId: string): string {
    if (!this.workOrderTypes) {
      return "(Work Order Types not available)";
    }

    let type = this.workOrderTypes.find(type => +workOrderTypeId > 0 && +type.value == +workOrderTypeId);
    return type ? type.name : "(Work Order Type " + workOrderTypeId + " not found)";
  }

  getStatusName(statusId: string): string {
    if (!this.statuses) {
      return ("Statuses not available.");
    }

    let status = this.statuses.find(status => !!status && status.value == statusId);
    return status ? status.name : "(Status " + statusId + " not found)";
  }
}
