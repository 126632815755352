import { Component, ViewChild, TemplateRef } from '@angular/core';
import { CredentialService } from '../../services/credential.service';
import { WebApiService } from '../../services/webApi.service';
import { Credentials } from '../../models/credentials.class';
import { TaskStatus } from '../../models/taskStatus.enum';
import { BsModalRef, ModalOptions, BsModalService } from '../../../../node_modules/ngx-bootstrap/modal';
import { AppStateService } from '../../services/appState.service';
import { Router } from '@angular/router';
import { RoleManagerState } from '../../models/roleManagerState.class';
import { RoleInfo } from '../../models/roleInfo.class'

@Component({
  templateUrl: './editRole.component.html'
})
export class EditRoleComponent {
  credentials: Credentials;
  roleManagerState: RoleManagerState;
  roleInfo: RoleInfo;
  nameError: string;
  validateFailed: boolean = false;
  migrateMembers: boolean = false;
  migrateRole: string = "";
  
  taskStatus = TaskStatus;
  submittingStatus: TaskStatus = TaskStatus.Pending;
  submittingMessage: string;
  submittingTitle: string;
  submittingModal: BsModalRef;
  @ViewChild('submittingModal')
  submittingModalTpl: TemplateRef<any>;

  deleteConfirmStatus: TaskStatus = TaskStatus.Pending;
  deleteConfirmMessage: string;
  deleteConfirmModal: BsModalRef;
  @ViewChild('deleteConfirmationModal')
  deleteConfirmModalTpl: TemplateRef<any>;

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }  

  constructor(credentialService: CredentialService, private _webApiService: WebApiService, private _bsModalService: BsModalService, private _appStateService: AppStateService, private _router: Router) {
    this.credentials = credentialService.credentials;
    this.roleManagerState = _appStateService.getAppState().roleManagerState;
    this.roleInfo = (this.roleManagerState.roleToEdit) ? Object.assign({}, this.roleManagerState.roleToEdit) : new RoleInfo();
  }

  validateRoleInfo(force: boolean = false): boolean {
    if (!force && !this.validateFailed)
      return;

    let errors: number = 0;
    this.nameError = "";

    if (!this.roleInfo.name) {
      this.nameError = "Role name is required.";
      errors++;
    }

    if (errors > 0)
      this.validateFailed = true;

    return errors == 0;
  }

  saveRoleInfo(): void {
    if (!this.validateRoleInfo(true))
      return;

    this.submittingStatus = TaskStatus.Running;
    this.submittingMessage = "Saving role..."
    this.submittingTitle = "Save Role";
    this.submittingModal = this._bsModalService.show(this.submittingModalTpl, this.modalOptions);

    ((this.roleInfo.id) ? this._webApiService.editRole$(this.roleInfo) : this._webApiService.addRole$(this.roleInfo)).subscribe(
      data => {
        this.submittingStatus = TaskStatus.Success;
        this.submittingMessage = "Role successfully saved."

        if (this.roleInfo.id) {
          // Updated existing user
          this.roleManagerState.roles[this.roleManagerState.roles.findIndex(role => role.id == this.roleInfo.id)] = data;
        }
        else {
          // Added new user
          this.roleManagerState.roles.push(data);
          this.roleManagerState.roles.sort((a, b) => { 
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
        }

        setTimeout(() => {
          this.roleManagerState.returnFromEdit = true;
          this._router.navigate(["/roles"]);
          this.closeStatus();
        }, 1500);  
      },
      error => {
        this.submittingStatus = TaskStatus.Failure;
        if (error.error) {
          this.submittingMessage = error.error;
        }
        else {
          this.submittingMessage = "There was an error saving the role.";
        }
      }
    );    
  }

  deleteRole(): void {
    this.closeDeleteConfirmation();
    this.submittingStatus = this.taskStatus.Running;
    this.submittingMessage = "Deleting role...";
    this.submittingTitle = "Delete Role";
    this.submittingModal = this._bsModalService.show(this.submittingModalTpl, this.modalOptions);

    this._webApiService.deleteRole$(this.roleInfo.id, this.migrateMembers, this.migrateRole).subscribe(
      data => {
        this.roleManagerState.roles.splice(this.roleManagerState.roles.findIndex(role => role.id == this.roleInfo.id), 1);
        this.submittingStatus = this.taskStatus.Success;
        this.submittingMessage = "Role successfully deleted.";

        setTimeout(() => {
          this.roleManagerState.returnFromEdit = !this.migrateMembers; // Forces roles reload after role migrations
          this._router.navigate(["/roles"]);
          this.closeStatus();
        }, 1500);
      },
      error => {
        this.submittingStatus = TaskStatus.Failure;
        if (error.error) {
          this.submittingMessage = error.error;
        }
        else {
          this.submittingMessage = "There was an error deleting the role.";
        }
      }
    );
  }

  selectMigrateMembers(select: boolean): void {
    this.migrateMembers = select;
    if (!this.migrateMembers) {
      this.migrateRole = "";
    }     
  }

  closeStatus(): void {
    this.submittingModal.hide();
  }

  showDeleteConfirmation(): void {
    this.selectMigrateMembers(false);
    this.deleteConfirmModal = this._bsModalService.show(this.deleteConfirmModalTpl, this.modalOptions);
  }

  closeDeleteConfirmation(): void {
    this.deleteConfirmModal.hide();    
  }

  goBack(): void {
    this.roleManagerState.roleToEdit = null;
    this.roleManagerState.returnFromEdit = true;
    this._router.navigate(["/roles"]);
  }

  goHome(): void {
    this._appStateService.resetUserManagerState();    
    this._router.navigate(["/home"]);
  }
}
