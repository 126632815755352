import { ValidationBase } from "./validationBase.class";
import { AppStateService } from "../../services/appState.service";
import { ValidationState } from "../validationState.class";
import { WorkOrderState } from "../workOrderState.class";

export class EditDescriptionValidation extends ValidationBase {
    constructor(appStateService: AppStateService) {
        super(appStateService);

        this._states = new Map<string, ValidationState>([
            ["description", new ValidationState()],
            ["division", new ValidationState()],
            ["type", new ValidationState()]
        ]);
    }

    validate(fields: string[]): void {
        let w: WorkOrderState = this._appState.workOrder;
        let v: ValidationState;

        // Problem description
        if (fields.indexOf("description") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("description"));
            if (!w.workOrderDescription) {
                v.errorMessage = "A problem description is required.";
            }
        }

        // Division
        if (fields.indexOf("division") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("division"));
            if (+w.divisionId < 0) {
                v.errorMessage = "A division must be specified.";
            }
        }

        // Work order type
        if (fields.indexOf("type") > -1 || !fields.length) {
            v = this._getClearedState(this._states.get("type"));
            if (+w.workOrderTypeId < 0) {
                v.errorMessage = "A work order type must be specified.";
            }
        }
    }
}