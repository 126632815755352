import { WorkOrder } from "./workOrder.class";
import { MapState } from "./mapState.class";
import { ImageAttachment } from "./ImageAttachment.class";

export class WorkOrderState extends WorkOrder {
    // Edit settings
    creatorType: string = "internal";
    locationType: string = "address";
    imagesToUpload: ImageAttachment[] = [];
    imagesProcessed: number = 0;
    imageUploadFailures: ImageAttachment[] = [];    

    private _mapStates: { [tab: string]: MapState; } = {
        "address": new MapState(),
        "intersection": new MapState(),
        "gps": new MapState()
    };

    constructor() {
        super();
    }

    getMapState(tab: string = ""): MapState {
        return this._mapStates[tab ? tab : this.locationType];
    }
}