import { Pipe, PipeTransform } from "@angular/core";
import { RoleInfo } from "../models/roleInfo.class";

@Pipe({
    name: 'roleInfoFilter',
    pure: false
})
export class RoleInfoFilterPipe implements PipeTransform {
    transform(roles: RoleInfo[], excludeRoles: string[] = null): RoleInfo[] {
        if (!roles) {
            return roles;
        }
        
        let rolesExcludingHidden = roles.filter(role => role.name.toLowerCase().trim() != "admin" && role.name.toLowerCase().trim() != "disabled");
        let rolesExcludingCustom = excludeRoles ? rolesExcludingHidden.filter(role => excludeRoles.every(excludedRole => excludedRole != role.name)) : rolesExcludingHidden;
        return rolesExcludingCustom;
    }
}