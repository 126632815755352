import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BottomNavTargets } from '../../models/BottomNavTargets.class';
import { ValidationService } from '../../services/validation.service';
import { AppStateService } from '../../services/appState.service';
import { WorkOrderState } from '../../models/workOrderState.class';
import { MapState } from '../../models/mapState.class';
import { CredentialService } from '../../services/credential.service';
import { WebApiService } from '../../services/webApi.service';
import { TaskStatus } from '../../models/taskStatus.enum';
import { Shape } from '../../models/shape.class';
import { WorkOrder } from '../../models/workOrder.class';
import { HttpEventType } from '../../../../node_modules/@angular/common/http';

@Component({
  templateUrl: './edit.component.html'  
})
export class EditComponent {
  id: string;
  workOrder: WorkOrderState;
  updatedWorkOrder: WorkOrder;
  taskStatus = TaskStatus;
  savingStatus: TaskStatus = TaskStatus.Pending;
  currentPath: string;
  navTargets: string[];
  validation: ValidationService;
  firstErrorRoute: string;
  uploadProgress: number = -1;

  // Validation error modal
  validationErrorsModal: BsModalRef;
  @ViewChild('validationErrorsModal')
  validationErrorsModalTpl: TemplateRef<any>;

  // Saving modal
  savingModal: BsModalRef;
  @ViewChild('savingModal')
  savingModalTpl: TemplateRef<any>;
  savingMessage: string;

  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }

  constructor(private _location: Location, 
              private _router: Router, 
              activatedRoute: ActivatedRoute,
              validationService: ValidationService,
              private _bsModalService: BsModalService, 
              appStatService: AppStateService,
              private _credentialService: CredentialService,
              private _webApiService: WebApiService) {    
    this.workOrder = appStatService.getAppState().workOrder;
    this.validation = validationService;    
    activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.navTargets = ['/edit/' + this.id + '/general', '/edit/' + this.id + '/location', '/edit/' + this.id + '/description'];
    });
  }

  getBottomNavTargets(): BottomNavTargets {
      let currentIndex = this.navTargets.findIndex((target) => target == this._location.path());
      
      return {
        back: (currentIndex < 1) ? null : this.navTargets[currentIndex - 1],
        next: (currentIndex == this.navTargets.length) ? null : this.navTargets[currentIndex + 1]
      };
  }

  showValidationErrors(): void {
    this.validationErrorsModal = this._bsModalService.show(this.validationErrorsModalTpl, this.modalOptions);
  }

  submit(): void {
    this.validation.editGeneral.validate([]);
    this.validation.editLocation.validate([]);
    this.validation.editDescription.validate([]);

    if (this.validation.editGeneral.getErrorCount() > 0) {
      this.firstErrorRoute = "/edit/" + this.id + "/general";
      this.showValidationErrors();
    }
    else if (this.validation.editLocation.getErrorCount() > 0) {
      this.showValidationErrors();
      this.firstErrorRoute = "/edit/" + this.id + "/location";
    }
    else if (this.validation.editDescription.getErrorCount() > 0) {
      this.showValidationErrors();
      this.firstErrorRoute = "/edit/" + this.id + "/description";
    }
    else {
      let postWo: WorkOrderState = new WorkOrderState();      
      Object.assign(postWo, this.workOrder);

      // Flatten map state based on the current location type
      let mapState: MapState = postWo.getMapState();
      postWo.shape = new Shape(mapState.lng, mapState.lat);
      if (postWo.locationType != "address") { postWo.addressNumber = ""; }
      if (postWo.locationType == "gps") { postWo.addressStreet = ""; }
      if (postWo.locationType != "intersection") { postWo.addressStreet2 = ""; }

      // Reset work order date to midnight to avoid sub-date comparison issues
      postWo.workOrderDate = this._setDateToMidnight(postWo.workOrderDate);

      // Remove internal working fields not needed for submission      
      delete postWo['creatorType'];
      delete postWo['locationType'];
      delete postWo['_mapStates'];
      delete postWo['imagesToUpload'];
      delete postWo['imagesProcessed'];
      delete postWo['imageUploadFailures'];

      if (!postWo.id) {
        delete postWo['id'];
        postWo.createdDate = this._setDateToMidnight(new Date());
        postWo.createdBy = this._credentialService.credentials.userName;
      }

      // POST work order to server
      this.savingMessage = "Sending work order to server...";
      this.savingStatus = this.taskStatus.Running;
      this.savingModal = this._bsModalService.show(this.savingModalTpl, this.modalOptions)

      if (!postWo.id) {
        this._webApiService.submitWorkOrder$(postWo).subscribe(
          data => {
            if (!data.id) {
              // Error condition
              this.savingMessage = "There was an error saving the work order.";
              this.savingStatus = this.taskStatus.Failure;
              return;
            }
            else {
              this._webApiService.getWorkOrder$(data.id).subscribe(
                data => {
                  this.updatedWorkOrder = new WorkOrder();
                  Object.assign(this.updatedWorkOrder, data);
                  this._uploadImages()
                },
                error => {
                  // Error condition;
                  this.savingMessage = "There was an error reloading the work order.";
                  this.savingStatus = this.taskStatus.Failure; 
                }
              );
            } 
          },
          error => {
            // Error condition;
            this.savingMessage = "There was an error saving the work order.";
            this.savingStatus = this.taskStatus.Failure;
          }
        );        
      }
      else {
        this._webApiService.updateWorkOrder$(postWo).subscribe(
          data => {
            this._webApiService.getWorkOrder$(postWo.id).subscribe(
              data => {
                this.updatedWorkOrder = new WorkOrder();
                Object.assign(this.updatedWorkOrder, data);
                this._uploadImages()
              },
              error => {
                // Error condition;
                this.savingMessage = "There was an error reloading the work order.";
                this.savingStatus = this.taskStatus.Failure; 
              }
            );
          },
          error => {
            // Error condition;
            this.savingMessage = "There was an error saving the work order.";
            this.savingStatus = this.taskStatus.Failure;            
          }
        );
      }
    }    
  }

  private _uploadImages(): void {
    if (this.workOrder.imagesToUpload.length == 0) {
      // Either we didn't have any images to upload or we sent them all
      this.savingMessage = "Work order saved.";
      this.savingStatus = this.taskStatus.Success;

      if (this.getFailureCount() == 0) {
        setTimeout(() => {
          this._reloadWorkOrder();
        }, 2000); 
        
      }      
    }
    else {
      // Make sure images isn't null
      if (!this.updatedWorkOrder.images) {
        this.updatedWorkOrder.images = [];
      }        

      this.savingMessage = 
        "Uploading image " + 
        (this.workOrder.imagesProcessed + 1) + 
        " of " + 
        (this.workOrder.imagesToUpload.length + this.workOrder.imagesProcessed) +
        "...";

        this.uploadProgress = 0;
        this._webApiService.uploadImage$(this.updatedWorkOrder.workOrderID, this.workOrder.imagesToUpload[0].file).subscribe(
          event => {
            if (event.type == HttpEventType.UploadProgress) {
              this.uploadProgress = event.loaded / event.total;
            }
            else if (event.type == HttpEventType.Response) {
              this.uploadProgress = -1;
              if (event.body.imageUrl) {
                this.updatedWorkOrder.images.push(event.body.imageUrl);
                this.workOrder.imagesToUpload.shift();              
              }
              else {
                //TODO: Report error
                this.workOrder.imageUploadFailures.push(this.workOrder.imagesToUpload.shift());
              }
              this.workOrder.imagesProcessed++;
              this._uploadImages();
            }
            else {
              this.uploadProgress = -1;
            }
          },
          error => {
            //TODO: Report error
            this.uploadProgress = -1;
            this.workOrder.imageUploadFailures.push(this.workOrder.imagesToUpload.shift());
            this.workOrder.imagesProcessed++;
            this._uploadImages();
          }
      );
    }
  }

  getFailureCount(): number {
    return this.workOrder.imageUploadFailures.length;
  }

  private _reloadWorkOrder(): void {
    // Overwite current work order state with updated return values and reset upload stats
    Object.assign(this.workOrder, this.updatedWorkOrder);
    this.workOrder.imagesToUpload = this.workOrder.imageUploadFailures;
    this.workOrder.imageUploadFailures = [];
    this.workOrder.imagesProcessed = 0;
    this.savingModal.hide();
    this._router.navigate(["/review/" + this.id + "/details"]);  
  }

  closeSavingStatus(): void {
    this.savingModal.hide();
  }

  closeAndReload(): void {
    this.closeSavingStatus();
    this._reloadWorkOrder();
  }

  showMe(): void {
    this.validationErrorsModal.hide();
    this._router.navigate([this.firstErrorRoute]);
  }

  private _setDateToMidnight(date: Date): Date {
    let newDate = new Date(date);
    newDate.setUTCHours(0);
    newDate.setUTCMinutes(0);
    newDate.setUTCSeconds(0);
    newDate.setUTCMilliseconds(0);

    return newDate;
  }
}
