import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'roleFilter',
    pure: false
})
export class RoleFilterPipe implements PipeTransform {
    transform(roles: string[], excludeRoles: string[] = null): string[] {
        if (!roles) {
            return roles;
        }
        
        let rolesExcludingHidden = roles.filter(role => role.toLowerCase().trim() != "admin" && role.toLowerCase().trim() != "disabled");
        let rolesExcludingCustom = excludeRoles ? rolesExcludingHidden.filter(role => excludeRoles.every(excludedRole => excludedRole != role)) : rolesExcludingHidden;
        return rolesExcludingCustom;
    }
}