import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../../services/webApi.service';
import { RoleInfo } from '../../models/roleInfo.class';

@Component({
  templateUrl: './manage.component.html'
})
export class ManageComponent implements OnInit {
  loading: boolean = true;
  loadingError: boolean = false;
  userCount: number = 0;
  roles: RoleInfo[] = [];

  constructor(private _webApiService: WebApiService) {
  }

  ngOnInit() {
    this._webApiService.getUserCountAndRoles$().subscribe(
      data => {
        // data is a forkJoin observable with user count first and roles second
        this.userCount = data[0];
        this.roles = data[1];
        this.loading = false;
      },
      error => {
        this.loadingError = true;
        this.loading = false;
      }
    );
  }
}
