import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: "attachmentNumber",
    pure: false
})
export class AttachmentNumberPipe implements PipeTransform {
    transform(attachmentUrl: string): string {
        if (!attachmentUrl) {
            return "";
        }

        var urlParts = attachmentUrl.split("/");
        var fileParts = urlParts[urlParts.length - 1].split(".");
        var caption = (fileParts.length > 1) ? fileParts[fileParts.length - 2] : fileParts[0];

        return (isNaN(+caption)) ? caption : (+caption).toString();
    }
}