import { JwtHelperService } from "@auth0/angular-jwt";

export class Credentials {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  roles: string[];
  isAdministrator: boolean = false;
  token: string;
  lifeTime: number;

  constructor(id: string, token: string, lifeTime: number) {
    this.id = id;
    this.token = token;
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(this.token);
    this.userName = decodedToken.sub;
    this.firstName = decodedToken.given_name;
    this.lastName = decodedToken.family_name;
    this.roles = decodedToken.roles || [];

    // JWT generation makes only a single assigned role a string instead of
    // an array. This restores it so Array.findIndex() works
    if (typeof this.roles === 'string') {
      this.roles = [this.roles];
    }

    this.lifeTime = lifeTime;

    // Administrator is a role like the others but is handled transparently from other role maintenance
    let adminIndex = this.roles.findIndex(role => role.toLowerCase() == "admin");
    if (adminIndex > -1) {
      this.isAdministrator = true;
      this.roles.splice(adminIndex, 1);
    }
  }

  static fromObject(credentialsObj: any) {
    if (!credentialsObj.id || !credentialsObj.userName || !credentialsObj.lastName || !credentialsObj.firstName || !credentialsObj.token || !credentialsObj.lifeTime) {
      return null;
    }

    return new Credentials(credentialsObj.id, credentialsObj.token, credentialsObj.lifeTime);
  }
}
