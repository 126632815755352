import { Component, ViewChild, TemplateRef } from '@angular/core';
import { CredentialService } from '../../services/credential.service';
import { WebApiService } from '../../services/webApi.service';
import { Credentials } from '../../models/credentials.class';
import { ChangePasswordRequest } from '../../models/changePasswordRequest.class';
import { TaskStatus } from '../../models/taskStatus.enum';
import { BsModalRef, ModalOptions, BsModalService } from '../../../../node_modules/ngx-bootstrap/modal';
import { isArray, isString } from 'util';

@Component({
  templateUrl: './account.component.html'
})
export class AccountComponent {
  credentials: Credentials;
  currentPassword: string;
  currentPasswordError: string;
  currentPasswordLastAttempt: string;
  newPassword: string = "";
  newPasswordError: string;
  newPasswordConfirm: string = "";
  newPasswordConfirmError: string;
  validateFailed: boolean = false;
  
  taskStatus = TaskStatus;
  submittingStatus: TaskStatus = TaskStatus.Pending;
  submittingMessage: string;
    
  submittingModal: BsModalRef;
  @ViewChild('submittingModal')
  submittingModalTpl: TemplateRef<any>;
  modalOptions: ModalOptions = {
    keyboard: false,
    ignoreBackdropClick: true,
    animated: false,
    class: "modal-dialog-centered"
  }  

  constructor(private _credentialService: CredentialService, private _webApiService: WebApiService, private _bsModalService: BsModalService) {
    this.credentials = _credentialService.credentials;
  }

  validatePasswordChange(force: boolean = false): boolean {
    if (!force && !this.validateFailed)
      return;

    let errors: number = 0;
    this.currentPasswordError = "";
    this.newPasswordError = "";
    this.newPasswordConfirmError = "";

    if (!this.currentPassword) {
      this.currentPasswordError = "Current password requried.";
      errors++;
    }

    if (this.currentPassword == this.currentPasswordLastAttempt) {
      this.currentPasswordError = "Current password is invalid.";
      errors++;
    }

    if (this.newPassword.length < 6) {
      this.newPasswordError = "Passwords must at least 6 characters in length.";
      errors++;
    }      

    if (this.newPassword != this.newPasswordConfirm) {
      this.newPasswordConfirmError = "New passwords don't match";
      errors++;
    }

    if (errors > 0)
      this.validateFailed = true;

    return errors == 0;
  }

  changePassword(): void {
    if (!this.validatePasswordChange(true))
      return;

    this.currentPasswordLastAttempt = null;
    this.submittingStatus = TaskStatus.Running;
    this.submittingMessage = "Changing password..."
    this.submittingModal = this._bsModalService.show(this.submittingModalTpl, this.modalOptions)
    
    this._webApiService.changePassword$(new ChangePasswordRequest(this.credentials.userName, this.currentPassword, this.newPassword)).subscribe(
      data => {
        this.submittingStatus = TaskStatus.Success;
        this.submittingMessage = "Password successfully changed."

        setTimeout(() => {
          this.validateFailed = false;
          this.currentPassword = "";
          this.newPassword = "";
          this.newPasswordConfirm = "";
          this.submittingModal.hide();
        }, 1500);  
      },
      error => {
        this.submittingStatus = TaskStatus.Failure;

        if (error.error.value && isArray(error.error.value)) {
          let errors = error.error.value;
          this.submittingMessage = errors[0].code + ": " + errors[0].description;
          if (errors.length > 1) {
            this.submittingMessage += " (+" + (errors.length - 1)  + " more error" + (((errors.length - 1) == 1) ? '' : 's') + ")"
          }
        }
        else {
          this.submittingMessage = error.error;
          if (isString(error.error) && String(error.error).toLocaleLowerCase().indexOf("current password is invalid") > -1) {
            this.currentPasswordLastAttempt = this.currentPassword;
            this.validatePasswordChange(true);
          }            
        }        
      }
    );
  }

  closeStatus(): void {
    this.submittingModal.hide();
  }
}
