import { PipeTransform, Pipe } from "@angular/core";
import { WorkOrder } from "../models/workOrder.class";

@Pipe({
    name: "location",
    pure: false
})
export class LocationPipe implements PipeTransform {
    transform(workOrder: WorkOrder): string {
        let w = workOrder;

        if (w.addressNumber && w.addressStreet) {
            return w.addressNumber + " " + w.addressStreet;
        }
        else if (w.addressStreet && w.addressStreet2) {
            return w.addressStreet + " and " + w.addressStreet2;
        }
        else {
            return w.shape.y + ", " + w.shape.x;
        }
    }
}